import * as React from 'react';
import { View } from 'tonva-react';
import { CProduct, IJKUnits } from './CProduct';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import classNames from 'classnames';

export class VSearchProductSiftView extends View<CProduct> {
    searchLines: any;
    brands: any[] = this.controller.brands.filter((el: any) => el.level === 1000);
    constructor(c: CProduct) {
        super(c);
        makeObservable(this, {
            searchLines: observable,
            brands: observable,
        });
    }

    render(param: any): JSX.Element {
        return React.createElement(observer(() => {
            this.searchLines = param;
            return <this.content  />;
        }));
    }

    onchangeSearchLines = (e: any, objKey: string, index: number) => {
        let result = this.controller.searchLines[objKey];
        if (result) result[index] = e.target.value;
    };

    private content = observer((param?: any) => {
        let { brand, purity, pack, price, molecularWeight, amount } = this.searchLines;
        return <>
            <div className=''>

                <div className='card mb-2 p-2'>
                    <div className='font-weight-bold' >品牌
                        <small className='float-right cursor-pointer' onClick={() => {
                            this.controller.isShowMoreBrand = true;
                        }} >更多</small>
                    </div>
                    <div className='row mx-0' >
                        {
                            this.brands.map((el: any) => {
                                let result: any = this.controller.searchLines['brand']?.findIndex((i: any) => el.id === i);
                                if (!this.controller.isShowMoreBrand) {
                                    if (result !== undefined && result > -1) {
                                        el.isChecked = true;
                                    } else {
                                        el.isChecked = false;
                                    };
                                };
                                return <span key={el.id} className="col-6 col-sm-4 px-2 text-center my-1" >
                                    <span onClick={() => {
                                        if (result > -1) {
                                            this.controller.searchLines['brand'].splice(result, 1);
                                            el.isChecked = false;
                                        } else {
                                            this.controller.searchLines['brand'].push(el.id);
                                            el.isChecked = true;
                                        };
                                        let res: any = this.controller.brands.find((i: any) => i.id === el.id);
                                        if (res) res.isChecked = el.isChecked;
                                    }}
                                        style={el.isChecked ? { "background": "red ", } : { "background": "#e6e6e6" }}
                                        className={classNames("badge badge-pill w-100 py-1 cursor-pointer",
                                            el.isChecked && 'text-white')}>{el.name}</span>
                                </span>
                            })
                        }
                    </div>
                </div>

                <div className='card mb-2 p-2'>
                    <div className='font-weight-bold' >纯度（%）</div>
                    <div >
                        <input defaultValue={purity[0]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            if (e.target.value > 100) e.target.value = 100;
                            this.onchangeSearchLines(e, "purity", 0);
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最小值' type="number" />
                        <span> - </span>
                        <input defaultValue={purity[1]} onChange={(e: any) => {
                             if (e.target.value < 0) e.target.value = 0;
                            if (e.target.value > 100) e.target.value = 100;
                            this.onchangeSearchLines(e, "purity", 1)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' max={100} placeholder='最大值' type="number" />
                    </div>
                </div>

                <div className='card mb-2 p-2'>
                    <div className='font-weight-bold' >包装</div>
                    <div >
                        <input defaultValue={pack[0]} onChange={(e: any) => { if (e.target.value < 0) e.target.value = 0; this.onchangeSearchLines(e, "pack", 0); }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder="最小值" type="number" />
                        <span> - </span>
                        <input defaultValue={pack[1]} onChange={(e: any) => { if (e.target.value < 0) e.target.value = 0; this.onchangeSearchLines(e, "pack", 1); }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder="最大值" type="number" />
                        <select defaultValue={pack[2]} onChange={(e: any) => { this.onchangeSearchLines(e, "pack", 2) }}
                            className="w-4c mb-1 form-control-sm d-inline-block border border-primary" name="" id="">
                            {IJKUnits.map((el: any) => <option key={el.id} value={el.value}>{el.value}</option>)}
                        </select>
                    </div>
                </div>
                <div className='card mb-2 p-2'>
                    <div className='font-weight-bold' >单价（元）</div>
                    <div >
                        <input defaultValue={price[0]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            this.onchangeSearchLines(e, "price", 0)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最低价' type="number" />
                        <span> - </span>
                        <input defaultValue={price[1]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            this.onchangeSearchLines(e, "price", 1)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最高价' type="number" />
                    </div>
                </div>

                <div className='card mb-2 p-2'>
                    <div className='font-weight-bold' >总价（元）</div>
                    <div >
                        <div className='row mx-0'>
                            <small className='col-12 col-sm-1 px-0 align-self-center'>总量</small>
                            <span className='col-12 col-sm-11 px-0'>
                                 <input defaultValue={amount[0]} onChange={(e: any) => { if (e.target.value < 0) e.target.value = 0; this.onchangeSearchLines(e, "amount", 0); }}
                                className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder="总量" type="number" />
                            <select defaultValue={amount[1]} onChange={(e: any) => { this.onchangeSearchLines(e, "amount", 1) }}
                                className="w-4c mb-1 form-control-sm d-inline-block border border-primary mr-2" name="" id="">
                                {IJKUnits.map((el: any) => <option key={el.id} value={el.value}>{el.value}</option>)}
                            </select>
                            </span>
                        </div>
                        <div className='row mx-0'>
                            <small className='col-12 col-sm-1 px-0 align-self-center'>价格</small>
                            <span className='col-12 col-sm-11 px-0'>
                                 <input defaultValue={amount[2]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            this.onchangeSearchLines(e, "amount", 2)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最低价' type="number" />
                        <span> - </span>
                        <input defaultValue={amount[3]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            this.onchangeSearchLines(e, "amount", 3)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最高价' type="number" />
                            </span>
                        </div>
                    </div>

                </div>
                <div className='card p-2'>
                    <div className='font-weight-bold' >分子量</div>
                    <div >
                        <input defaultValue={molecularWeight[0]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            this.onchangeSearchLines(e, "molecularWeight", 0)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最小值' type="number" />
                        <span> - </span>
                        <input defaultValue={molecularWeight[1]} onChange={(e: any) => {
                            if (e.target.value < 0) e.target.value = 0;
                            this.onchangeSearchLines(e, "molecularWeight", 1)
                        }}
                            className='text-center w-6c mb-1 form-control-sm d-inline-block border border-primary' placeholder='最大值' type="number" />
                    </div>
                </div>
            </div>
        </>
    });
}