// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { Res, setRes, TFunc, UI, uqStringify } from "tonva-react";
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import { FieldItem, FieldItemInt, FieldItemNum, FieldItemString, FieldItemId } from "tonva-react";
import { CustomerDeveloped } from "./JkCoupon";

/*--fields--*/
const fields = {
	id: {
		"name": "id",
		"type": "id",
		"isKey": false,
		"label": "Id"
	} as FieldItemId,
	sales: {
		"name": "sales",
		"type": "id",
		"isKey": false,
		"label": "Sales"
	} as FieldItemId,
	customer: {
		"name": "customer",
		"type": "id",
		"isKey": false,
		"label": "Customer"
	} as FieldItemId,
	startDate: {
		"name": "startDate",
		"isKey": false,
		"label": "StartDate"
	} as undefined,
	result: {
		"name": "result",
		"isKey": false,
		"label": "Result"
	} as undefined,
	comments: {
		"name": "comments",
		"type": "string",
		"isKey": false,
		"widget": "string",
		"label": "Comments"
	} as FieldItemString,
};
/*==fields==*/

const fieldArr: FieldItem[] = [
	fields.sales, fields.customer, fields.startDate, fields.result, fields.comments, 
];

export const ui: UI = {
	label: "CustomerDeveloped",
	fieldArr,
	fields,
};

const resRaw: Res<any> = {
	$zh: {
	},
	$en: {
	}
};
const res: any = {};
setRes(res, resRaw);

export const t:TFunc = (str:string|JSX.Element): string|JSX.Element => {
	return res[str as string] ?? str;
}

export function render(item: CustomerDeveloped):JSX.Element {
	return <>{uqStringify(item)}</>;
};
