import { observable, makeObservable } from 'mobx';
import { BoxId, nav, QueryPager, User } from "tonva-react";
import { CUqBase } from '../CBase';
import { VProduct } from './VProduct';
import { VProductList } from './VProductList';
import { LoaderProductChemicalWithBase, LoaderProductChemicalWithPrices } from './itemLoader';
import { VProductDelivery } from './VProductDelivery';
import { VCartProuductView, VProductWithPrice, VProductPrice, VProuductView2 } from './VProductView';
import { VChemicalInfoInCart } from './VChemicalInfo';
import { VProductList_Web } from './VProductList_Web';
import { VProduct_Web } from './VProduct_Web';
import { ProductItem } from '../tools/ProductItem';
import { CFavorites } from '../customer/CFavorites';
import { VPDFView } from './VPDFView';
import { VVerifyCode } from './VVerifyCode';
import { GLOABLE } from 'cartenv';
import { CApp } from 'CApp';
import { ElasticSearchPager, productCatalogUrlGen, productUrlGen } from 'tools/elasticSearchPager';
import { VSearchProductSiftView } from './VSearchProductSiftView';
import { VProductCustom } from './VProductCustom';
import { VProductMaterialView } from './VProductMaterialView';

/*
class PageProducts extends PageItems<any> {

    private searchProductQuery: Query;

    constructor(searchProductQuery: Query) {
        super();
        this.firstSize = this.pageSize = 10;
        this.searchProductQuery = searchProductQuery;
    }

    protected async load(param: any, pageStart: any, pageSize: number): Promise<any[]> {
        if (pageStart === undefined) pageStart = 0;
        let ret = await this.searchProductQuery.page(param, pageStart, pageSize, false);
        return ret;
    }

    protected setPageStart(item: any): any {
        this.pageStart = item === undefined ? 0 : item.seq;
    }
}
*/
/**
 * 默认的包装单位
 */
export const IJKUnits: any[] = [
    { id: 1, value: "mg" },
    { id: 2, value: "g" },
    { id: 3, value: "kg" },
    { id: 4, value: "ml" },
    { id: 5, value: "L" },
];

type stringKey = Record<string, any[]>;
/**
 * 默认的筛选数据
 */
export const siftSearchDefultData: stringKey = {
    brand: ["", ""],
    purity: ["", ""],
    pack: ["", "", IJKUnits[0].value],
    price: ["", ""],
    molecularWeight: ["", ""],
    amount: ["", IJKUnits[0].value, "", ""],
};

export class CProduct extends CUqBase {
    //pageProducts: PageProducts;
    productsPager: QueryPager<any> | ElasticSearchPager<any>;
    productSpecFiles: any[] = [];
    productMSDSFiles: any[] = [];
    futureDeliveryTimeDescriptionContainer: { [cacheId: string]: string } = {};
    chemicalInfoContainer: { [productId: number]: any } = {};
    verifyCode: any;
    currentFileName: any;
    currentLanguage: any;
    currentProduct: any;
    brands: any[] = [];     /* 初始的品牌列表 */
    searchLines: stringKey = siftSearchDefultData; /* 筛选条件记录 */
    isShowMoreBrand: boolean = false;   /* 是否展示更多品牌 */

    constructor(cApp: CApp) {
        super(cApp);

        makeObservable(this, {
            productsPager:observable,
            productSpecFiles: observable,
            productMSDSFiles: observable,
            futureDeliveryTimeDescriptionContainer: observable,
            chemicalInfoContainer: observable,
            verifyCode: observable,
            currentFileName: observable,
            currentLanguage: observable,
            currentProduct: observable,
            brands: observable,
            searchLines: observable,
            isShowMoreBrand: observable,
        });
    }

    protected async internalStart(param?: any) {
        this.searchLines = siftSearchDefultData;
        await this.searchByKey(param);
        await this.getBrandLines();
        this.openVPage(VProductList, param);
    }

    renderProductList2(key: any) {
        return this.renderView(VProductList, key);
    }

    initSearchLineBody = () => {
        let obj: any = {};
        let objIndex: any = { pack: 2, amount: 1 };
        Object.keys(this.searchLines).forEach((el: any) => {
            let result: any[] = this.searchLines[el];
            if (el == "brand") result = result?.filter((i: any) => i) || [];
            let lenIndex: number = objIndex[el] || -1;
            let res:boolean = result.some((i: any, index: number) => {
                if (index === lenIndex) return false;
                return i;
            });
            obj[el] = res ? result : [];
        });
        return obj;
    }

    async searchByKey(key: string) {
        let { currentSalesRegion } = this.cApp;
        // //this.pageProducts = new PageProducts(this.uqs.product.SearchProduct);
        // this.productsPager = new QueryPager<any>(this.uqs.product.SearchProduct, 10, 10);
        // //this.pageProducts.first({ keyWord: key, salesRegion: currentSalesRegion.id });
        // this.productsPager.first({ keyWord: key, salesRegion: currentSalesRegion.id })
        // console.log(this.productsPager);
        // let url = GLOABLE.CONTENTSITE + '/api/product/search';
        let keyWord = encodeURIComponent(key);
        let urlGen = new productUrlGen();
        urlGen.setBody(this.initSearchLineBody());
        this.productsPager = new ElasticSearchPager<any>(undefined, urlGen, 10, 10);
        await this.productsPager.first({ keyWord: keyWord, salesRegion: currentSalesRegion.id });
    }

    searchWebByKey(key: string) {
        let { currentSalesRegion } = this.cApp;
        //this.pageProducts = new PageProducts(this.uqs.product.SearchProduct);
        this.productsPager = new QueryPager<any>(this.uqs.product.SearchProduct, 3, 3);
        //this.pageProducts.first({ keyWord: key, salesRegion: currentSalesRegion.id });
        this.productsPager.first({ keyWord: key, salesRegion: currentSalesRegion.id })
    }

    async searchByCategory(category: any) {
        // let { currentSalesRegion } = this.cApp;
        // //this.pageProducts = new PageProducts(this.uqs.product.SearchProductByCategory);
        // this.productsPager = new QueryPager<any>(this.uqs.product.SearchProductByCategory, 10, 10);
        // let { productCategoryId, name } = category;
        // //this.pageProducts.first({ productCategory: productCategoryId, salesRegion: currentSalesRegion.id });
        // await this.productsPager.first({ productCategory: productCategoryId, salesRegion: currentSalesRegion.id })
        // console.log(2,this.productsPager);
        let { currentSalesRegion } = this.cApp;
        let { productCategoryId, name } = category;
        let url = GLOABLE.CONTENTSITE + '/api/product-catalog';
        let keyWord = productCategoryId + '/products';
        let urlGen = new productCatalogUrlGen();
        this.productsPager = new ElasticSearchPager<any>(url, urlGen, 10, 10);
        await this.productsPager.first({ keyWord: keyWord, salesRegion: currentSalesRegion.id });
        this.searchLines = siftSearchDefultData;
        await this.getBrandLines();
        this.openVPage(VProductList, name);
    }

    /**
     *
     */
    showProductDetail = async (productId: BoxId | any, JumpSource?: any, isShowMSCU: boolean = false) => {

        if (productId) {
            let discount = 0, product = productId;
            /*
            product = await this.uqs.product.ProductX.load(productId);
            let { currentUser } = this.cApp;
            if (currentUser.hasCustomer) {
                let discountSetting = await this.uqs.customerDiscount.GetDiscount.obj({ brand: product.brand.id, customer: currentUser.currentCustomer });
                discount = discountSetting && discountSetting.discount;
            }
            */
            let loader = new LoaderProductChemicalWithPrices(this.cApp);
            let productData = await loader.load(productId);
            if (JumpSource) this.closePage();
            let newProduct: any = typeof product === "number" ?
                await this.uqs.product.ProductX.boxId(product).assure() : product;
            await this.getVerifyCode();
            this.openVPage(VProduct, { productData, product: newProduct, discount, isShowMSCU: isShowMSCU });
        };
    }

    /*   renderProductCarryFavorites = (product: any) => {
          return this.renderView(VProductCarryFavorites, { product: product });
      } */

    renderProductPrice = (product: BoxId, discount: number) => {
        return this.renderView(VProductPrice, { product: product, discount: discount });
    }

    renderProductWithPrice = (product: BoxId) => {
        return this.renderView(VProductWithPrice, product);
    }

    getProductPrice = async (product: BoxId, salesRegionId: number, discount: number) => {
        let { id: productId } = product;
        let { currentSalesRegion, cart, currentLanguage, uqs } = this.cApp;

        // let prices = await uqs.product.PriceX.table({ product: product, salesRegion: salesRegionId });
        let prices: any[] = await uqs.JkProduct.GetProductPrices.table({ product: productId, salesRegion: currentSalesRegion?.id });
        let priceSet = prices.filter(e => e.discountinued === 0 && e.expireDate > Date.now() && e.salesLevel?.id === 1).sort((a, b) => a.retail - b.retail).map(element => {
            let ret: any = {};
            ret.pack = element.pack;
            ret.retail = element.retail;
            if (discount !== 0)
                ret.vipPrice = Math.round(element.retail * (1 - discount));
            ret.currency = currentSalesRegion.currency;
            ret.quantity = cart.getQuantity(productId, element.pack.id)
            return ret;
        });
        let promises: PromiseLike<any>[] = [];
        priceSet.forEach(v => {
            promises.push(uqs.promotion.GetPromotionPack.obj({ product: productId, pack: v.pack, salesRegion: currentSalesRegion, language: currentLanguage }));
        })
        let results = await Promise.all(promises);

        for (let i = 0; i < priceSet.length; i++) {
            let promotion = results[i];
            let discount = promotion && promotion.discount;
            if (discount)
                priceSet[i].promotionPrice = Math.round((1 - discount) * priceSet[i].retail);
        }
        return priceSet;
    }

    renderDeliveryTime = (pack: BoxId) => {
        return this.renderView(VProductDelivery, pack);
    }

    getInventoryAllocation = async (productId: number, packId: number, salesRegionId: number) => {
        return await this.uqs.warehouse.GetInventoryAllocation.table({ product: productId, pack: packId, salesRegion: this.cApp.currentSalesRegion });
    }

    getFutureDeliveryTimeDescription = async (productId: number, salesRegionId: number) => {
        let cacheId = productId + '_' + salesRegionId;
        if (this.futureDeliveryTimeDescriptionContainer[cacheId] === undefined) {
            let isRestrict: boolean = false;
            let futureDeliveryTime: any[] = await this.uqs.JkProduct.ProductDeliveryTime.table({ product: productId, salesRegion: salesRegionId });
            if (!futureDeliveryTime.length) {
                futureDeliveryTime = await this.uqs.JkProduct.GetFutureDeliveryTime.table({ product: productId, salesRegion: salesRegionId });
                if (futureDeliveryTime.length) isRestrict = await this.getIsRestrict(productId);
            };
            // let futureDeliveryTime = await this.uqs.product.GetFutureDeliveryTime.table({ product: productId, salesRegion: salesRegionId });
            if (futureDeliveryTime.length > 0) {
                let currObjIndex: number = 0;
                if (futureDeliveryTime.length > 1) {
                    let isWXNum: number = isRestrict ? 1 : 0;
                    let findIndex: number = futureDeliveryTime.findIndex((el: any) => el.isRestrict !== undefined && el.isRestrict === isWXNum);
                    if (findIndex > -1) currObjIndex = findIndex;
                };
                let { minValue, maxValue, unit, deliveryTimeDescription } = futureDeliveryTime[currObjIndex];

                this.futureDeliveryTimeDescriptionContainer[cacheId] = minValue + (maxValue > minValue ? '~' + maxValue : '') + ' ' + unit;
            } else {
                this.futureDeliveryTimeDescriptionContainer[cacheId] = null;
            }
        }
        return this.futureDeliveryTimeDescriptionContainer[cacheId];
    };

    /* 查询是否是危化品 */
    getIsRestrict = async (productId: number) => {
        let isRestrict: boolean = false;
        let productChemical = await this.cApp.uqs.JkProduct.ProductChemical.obj({ product: productId });
        if (productChemical && productChemical.chemical) {
            let JNKRestrictByChemical: any[] = await this.uqs.JkChemical.ChemicalJNKRestrict.table({ chemical: productChemical.chemical });
            if (JNKRestrictByChemical.length) {
                let promise: PromiseLike<any>[] = [];
                for (let key of JNKRestrictByChemical) {
                    promise.push(this.uqs.JkChemicalSecurity.JNKRestrict.load(key?.jnkRestrict?.id));
                };
                let result = await Promise.all(promise);
                isRestrict = result.filter((el: any) => el).some((el: any) => el?.no?.indexOf('WX') > -1);
            };
        };
        return isRestrict;
    }

    renderChemicalInfoInCart = (product: BoxId) => {
        return this.renderView(VChemicalInfoInCart, product);
    }

    getChemicalInfo = async (productId: number) => {
        if (this.chemicalInfoContainer[productId] === undefined) {
            this.chemicalInfoContainer[productId] = await this.uqs.product.ProductChemical.obj({ product: productId });
        }
    }

    renderFavoritesLabel = (product: number) => {
        let { cApp } = this;
        let { cFavorites } = cApp;
        return cFavorites.renderFavoritesLabel(product);
    }

    /**
     *
     */
    renderProduct = (product: any) => {
        return this.renderView(VProuductView2, product);
    }

    getProductAndDiscount = async (productId: BoxId) => {
        let product = await this.uqs.product.ProductX.load(productId);
        let discount = 0;
        let { currentUser } = this.cApp;
        if (currentUser.hasCustomer) {
            let discountSetting = await this.uqs.customerDiscount.GetDiscount.obj({ brand: product.brand.id, customer: currentUser.currentCustomer });
            discount = discountSetting && discountSetting.discount;
        }
        return { product: product, discount: discount };
    }

    renderCartProduct = (product: BoxId) => {
        return this.renderView(VCartProuductView, product);
    }


    /**
     * 获取产品搜索筛选组件
     */
    renderSearchProductSift = (key: string) => {
        return this.renderView(VSearchProductSiftView, this.searchLines);
    }

    renderProductList = (key: string) => {
        this.searchWebByKey(key);
        return this.renderView(VProductList_Web, key)
    }

    renderProductWeb = async (key: any) => {
        if (key) {
            let discount = 0;
            let loader = new LoaderProductChemicalWithPrices(this.cApp);
            let productData = await loader.load(key);
            let param: ProductItem = {
                product: this.cApp.uqs.product.ProductX.boxId(key),
                productData: productData,
                discount: discount,
            }
            return this.renderView(VProduct_Web, param);
        }
    }

    /**
     * 获取官网在售品牌
     */
    getBrandLines = async () => {
        let { currentSalesRegion } = this.cApp;
        this.brands = await this.uqs.JkProduct.GetBrandSalesRegion.table({ salesRegion: currentSalesRegion });
    }

    /**
     * 在线预览PDF,开启验证
     */
    ToVerifyPdf = async (fileInfo: any) => {
        let { currentUser } = this.cApp;
        let { content, product } = fileInfo;
        let reg = /\w*\//ig
        this.currentFileName = content.fileName ? content.fileName.replace(reg, '').toLocaleUpperCase() : undefined;
        this.currentLanguage = content.language;
        this.currentProduct = product;
        let loginCallback = async (user: User) => {
            await currentUser.setUser(user);
            this.closePage(1);
            await this.openVerifyCode();
        };
        if (!this.isLogined)
            nav.showLogin(loginCallback, true);
        else
            await this.openVerifyCode();
    }

    /**
     * 验证码页面
     */
    openVerifyCode = async () => {
        this.getVerifyCode();
        this.openVPage(VVerifyCode);
    }

    /**
     * PDF文件预览页面
     */
    openPDFView = async (fileUrl: any) => {
        this.openVPage(VPDFView, fileUrl);
    }

    /**
     * 获取PDF文件流
     */
    getPDFFileUrl = async (captcha: string) => {
        let lang = this.currentLanguage ? this.currentLanguage.id : undefined;
        let productId = this.currentProduct ? this.currentProduct.id : undefined;
        // let res = await window.fetch(GLOABLE.CONTENTSITE + `/partial/productpdffile/${captcha}/${32}/${7084}`);
        let res = await window.fetch(GLOABLE.CONTENTSITE + `/partial/productpdffile/${captcha}/${lang}/${productId}`);
        if (res.status === 200) {
            let content = await res.arrayBuffer();
            return content;
        } else {
            return {
                status: res.status,
                msg: res.status !== 412 ? res.statusText : '验证码错误!'
            }
        }
    }

    /**
     * 获取PDF文件流
     */
    getPDFFileUrl2 = async (row: any) => {
        let { origin, lang, lot, captcha, materialType } = row;
        if (materialType === 'msds')
            return await this.fetchPdf(`/partial/productMsdsFileByOrigin/${lang}/${origin}/${captcha}`);
        if (materialType === 'um')
            return await this.fetchPdf(`/partial/productUserManualFileByOrigin/${lang}/${origin}/${captcha}`);
        if (materialType === 'spec')
            return await this.fetchPdf(`/partial/productSpecFileByOrigin/${origin}/${captcha}`);
        if (materialType === 'coa') {
            let getLot: any = await this.getLotByOrigin({ lotnumber: lot, origin: origin });/* LV50T103 911810  */
            let res = {
                status: 404,
                msg: `暂时不能提供质检报告（COA）, 您可能输入了错误的产品编号（批号），或者您查询产品品牌暂时不能提供质检报告。`
            };
            if (!getLot || !getLot?.id?.id) return res;
            let lotId: number = getLot?.id?.id;
            let getCOAPdf: any = await this.fetchPdf(`/partial/coaFile/${lotId}`);
            if (getCOAPdf && getCOAPdf.content) return getCOAPdf;
            return res;
            /* let getCoaByOrigin = await this.getCoaByOrigin(getLot.id);
            if (!getCoaByOrigin) return res;
            return {
                fileFormat: "coaJson",
                content: {
                    ...getLot,
                    ...getCoaByOrigin,
                    origin: origin
                }
            }; */
        };
    }

    getCoaByOrigin = async (lotNumber: string | number) => {
        return await this.uqs.JkProduct.COA.obj({ lot: lotNumber });
    }

    getLotByOrigin = async (row: any) => {
        let { origin, lotnumber } = row;
        return await this.uqs.JkProduct.GetLotByLotnumber.obj({ lotnumber: lotnumber, origin: origin });
    }

    fetchPdf = async (url: string) => {
        let fileUrl = GLOABLE.CONTENTSITE + url;
        let res = await window.fetch(fileUrl, {
            // credentials: 'include'
        });
        if (res.status === 200) {
            let content = await res.arrayBuffer();
            return {
                content: content,
                fileUrl: fileUrl,
                fileFormat: "pdf",
            };
        } else {
            let result: any;
            try { result = await res.json(); } catch (error: any) { }
            return {
                status: res.status,
                captchaErr: result ? true : false,
                msg: result ? result.message : "Not Found"
                // msg: res.status !== 400 ? res.statusText : '验证码错误!'
            }
        }
    }

    tabProductMaterialView = (param: any) => this.renderView(VProductMaterialView, param);

    /**
     * 获取验证码
     */
    getVerifyCode = async () => {
        let timer = (new Date()).getTime()
        this.verifyCode = GLOABLE.CONTENTSITE + `/partial/captcha/?timer=${timer}`;//'http://dummyimage.com/200x100';
    }

    /**
     * 获取产品MSDS文件
     */
    getProductMSDSFile = async (product: any) => {
        this.productMSDSFiles = await this.uqs.product.ProductMSDSFile.table({ product });
        this.productMSDSFiles = this.productMSDSFiles.sort((a: any, b: any) => b.language.id - a.language.id);
    }

    /**
     * 获取产品Spec文件
     */
    getProductSpecFile = async (product: any) => {
        this.productSpecFiles = await this.uqs.product.ProductSpecFile.table({ product });
    }

    getProduct2c = async (productId: number) => {
        let { JkProduct } = this.uqs;
        let result: any = await JkProduct.ID({ IDX: JkProduct.Product2c, id: productId });
        return result.length ? true : false;
    }

    /**
     * 新的临时产品详情
     */
    showProductDetailCustom = async (productId: BoxId | any) => {
        let { cHome, uqs } = this.cApp;
        if (productId) {
            let product = productId;
            let loader = new LoaderProductChemicalWithBase(this.cApp);
            let productData = await loader.load(productId);
            /* let newProduct: any = typeof product === "number" ? await this.uqs.product.ProductX.boxId(product).assure() : product; */
            // await cHome.getSlideShow();
            await this.getVerifyCode();
            this.openVPage(VProductCustom, { productData });
        }
    }
}

export function productPropIsValid(value: string) {
    return !(value === null || value === undefined || value === '0' || value === 'N/A');
}