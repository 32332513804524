import * as React from 'react';
import { tv, View } from "tonva-react";
import { CProduct } from './CProduct';
import { observer } from 'mobx-react';
import { makeObservable, observable } from 'mobx';
import { GLOABLE } from 'cartenv';

export class VProductDelivery extends View<CProduct> {

    inventoryAllocation: any[];
    futureDeliveryTimeDescription: string;
    anotherInventory: any;
    constructor(c: CProduct) {
        super(c);

        makeObservable(this, {
            inventoryAllocation: observable,
            futureDeliveryTimeDescription: observable,
            anotherInventory: observable,
        });
    }

    render(param: any): JSX.Element {
        let { obj: packObj, id: packId } = param;
        let { owner: productId } = packObj;
        let { controller } = this;
        let { currentSalesRegion } = controller.cApp;
        // this.getAnotherInventory(packId);
        return <this.content packId={packId} productId={productId} currentSalesRegion={currentSalesRegion} />;
    }

    private initInventoryAllocation = async (productId: number, packId: number, salesRegionId: number) => {
        if (this.inventoryAllocation === undefined)
            this.inventoryAllocation = await this.controller.getInventoryAllocation(productId, packId, salesRegionId);
    }

    private initFutureDeliveryTimeDescription = async (productId: number, salesRegionId: number) => {
        if (this.futureDeliveryTimeDescription === undefined)
            this.futureDeliveryTimeDescription = await this.controller.getFutureDeliveryTimeDescription(productId, salesRegionId);
    }

    private async getAnotherInventory(packId: number) {
        try {
            if (this.anotherInventory === undefined) {
                let res = await fetch(GLOABLE.TCIGETPACK, {
                    method: "POST",
                    headers: { "Content-Type": "application/json;charset=UTF-8" },
                    body: JSON.stringify({ productxPackxId: packId })
                });
                if (res.ok) {
                    let ret: any = await res.json();
                    if (ret.data) {
                        let { quantitySh, quantity_Jp, quantity_Tj } = ret.data;
                        quantitySh = quantitySh.replace(">", "");
                        quantity_Jp = quantity_Jp.replace(">", "");
                        quantity_Tj = quantity_Tj.replace(">", "");
                        this.anotherInventory = {
                            packId: packId,
                            isAnother: true,
                            data: [
                                { name: "国内", quantity: Number(quantitySh) + Number(quantity_Tj) },
                                { name: "国外", quantity: Number(quantity_Jp) },
                            ]
                        };
                        let p:any[] = this.anotherInventory.data.filter((el: any) => el.quantity !== 0);
                        let warehouseParam: any = { boxName: "warehouse", isUndefined: false, assure: async () => { }, equ(id: number) { return true } };
                        let data = p.map((el: any) => ({
                            warehouse: { id: undefined, obj: { id: undefined, name: el.name }, ...warehouseParam },
                            quantity: el.quantity, isAnother: true, deliveryTimeDescription: "",
                        }));
                        if (this.inventoryAllocation && this.inventoryAllocation.length > 0 && this.anotherInventory) {
                                this.inventoryAllocation = [this.inventoryAllocation, data].flat();
                        }
                    };
                };
            };
        } catch (error: any) { this.anotherInventory = undefined; }
    }

    private content = observer((param: any): any => {

        let deliveryTimeUI;
        let { packId, productId, currentSalesRegion } = param;
        if (productId) {
            this.initInventoryAllocation(productId, packId, currentSalesRegion);
            this.initFutureDeliveryTimeDescription(productId, currentSalesRegion);
        }
        if (this.inventoryAllocation === undefined || this.futureDeliveryTimeDescription === undefined)
            return null;
        let restrict = this.inventoryAllocation.some((v:any)=> v?.quantity !== 0) ? 1:  0;
        if (this.inventoryAllocation && this.inventoryAllocation.length > 0) {
            deliveryTimeUI = this.inventoryAllocation.map((v: any, index) => {
                let { warehouse, quantity, deliveryTimeDescription, isAnother } = v;
                if (quantity > 0) {
                    restrict += 1;
                    return <div key={index} className="text-success">
                        {!isAnother ?
                            tv(warehouse, (values: any) =>
                            <span className="small">{values?.name ? String(values.name).replace('库房', '') : null}</span>) : <span className="small">{warehouse.obj?.name}</span> }
                        : {(quantity > 10 ? '>10' : quantity)}
                        {deliveryTimeDescription}
                    </div>
                } else {
                    if (restrict === 0) {
                        if (!this.futureDeliveryTimeDescription) return null;
                        restrict += 1;
				        return <div key={index}>{'期货: ' + this.futureDeliveryTimeDescription}</div>;
                    }
                    return undefined;
                }
            });
        } else {
            deliveryTimeUI = <><div>{this.futureDeliveryTimeDescription && '期货: ' + this.futureDeliveryTimeDescription}</div>{this.renderAnI()}</>;
        }
        return deliveryTimeUI;
    });

    renderAnI = () => {
        if (this.anotherInventory) {
            let isRenderAnI: any[] = this.anotherInventory.data.filter((el: any) => el.quantity !== 0);
            if (isRenderAnI.length) {
                return <>{isRenderAnI.map((el: any) => (<div key={el.name} className="text-success" >{`${el.name}: ${el?.quantity > 10 ? '>10' : el?.quantity}`}</div>))}</>;
            }
        }
        return <></>;
    }
}