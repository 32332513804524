import * as React from 'react';
import { Page, VPage } from "tonva-react";
import marked from 'marked';
import { CMe } from './CMe';
import { makeObservable, observable } from 'mobx';

export const privacyUI = `<div class="container">
    <p class="mt-lg-1" style="line-height:1.8em; "> 百灵威科技有限公司（以下简称 “百灵威”）极为重视用户（指注册、登录、使用百灵威购物APP和百灵威官方网站服务的个人、单位，下同）的数据保护和隐私问题，并遵守中国相关法律法规要求。本《隐私政策》（以下简称“本政策”）将会帮助用户了解百灵威如何收集、使用以及保护用户的数据。请在使用百灵威购物APP（以下简称“APP”）和百灵威官方网站（以下简称“网站”）之前，阅读、了解本政策。</p>
    <h6><b>一、适用范围</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 本政策适用百灵威APP和网站收集的数据，不适用于任何其他的数据收集或处理，包括但不限于通过未标示出本政策直接链接的百灵威其他产品或服务，或者在百灵威APP或网站上可能链接至的任何第三方站点由其他公司所进行的数据收集。</p>
	<h6><b>二、百灵威收集的数据及用途</b></h6>
	<p class="mt-lg-1"> 1. 用户的基本信息</p>
	<p class="mt-lg-1" style="line-height:1.8em; "> 包括：<br>
		（1）创建/维护百灵威账号所提交的信息，如：用户的姓名、所在单位、地址、电话号码、电子邮箱等在APP和网站的账户信息页面用户提供的所有信息。<br>
		（2）为正常使用APP或网站的订购功能，用户所提供的收货人的姓名、单位名称、电话号码、电子邮箱、地址、邮编等信息，以及为购买产品而提供的支付信息。<br>
		（3）为正常开具发票，用户所提供的发票相关信息，包含单位全称、纳税人识别码、注册地址、注册电话、开户银行、银行账号等。<br>
		百灵威收集用户的基本信息，仅会出于以下所述目的：<br>
		</p><ul> 
		    <li>管理客户关系；</li>
		    <li>审核用户订单的合规性；</li>
		    <li>完成用户的订单，包括开具发票；</li>
		    <li>向用户提供完整的物流货运服务；</li>
	        <li>向用户推送相关的产品、活动或资料信息。</li>
	    </ul>
		用户可以选择不与百灵威共享用户的个人信息，但在这种情况下，用户将无法创建百灵威用户帐户。如果没有用户帐户，用户仍然可以浏览网站的大部分内容，但无法在线订购或查看某些页面。
	<p></p>
	<p class="mt-lg-1"> 2. APP和网站技术统计数据</p>
	<p class="mt-lg-1" style="line-height:1.8em; "> 
		（1）用户使用和浏览百灵威APP和网站时，百灵威会收集数据，包含用户使用的浏览器和操作系统的识别数据、访问所通过的网站、请求设备的互联网协议（IP）地址、IP端口、访问日期和时间、传输的数据及大小、访问的页面、访问状态、在百灵威APP或网站上停留的时间、在百灵威APP或网站上进行的交易有关的信息，以及其他“点击流”数据。<br>
		如果用户使用移动应用程序访问百灵威APP或网站，百灵威也会收集用户的设备信息（例如设备操作系统版本和设备硬件）、唯一设备标识符（包括设备IP地址）、移动电话号码及地理位置数据。作为百灵威APP和网站标准化运行的一部分，这些数据是自动生成并被收集的。<br>
		百灵威收集、处理、使用以上信息，仅会出于以下所述目的： <br>
		</p><ul>
		    <li>确保用户能够使用百灵威APP和网站;</li>
			<li>系统安全和网络基础设施的技术管理；</li>
			<li>用于实现百灵威APP和网站功能及用户在百灵威站点体验的个性化；</li>
			<li>在其他方面总体改善百灵威APP和网站的内容、设计和导航；</li>
			<li>在符合中国相关法律法规允许的范围内，百灵威可能会把所收集的各类数据组进行整合，用于营销分析和类似调研，以助于百灵威做出经营决策。该等分析和调研活动可能通过第三方服务，使用由我们收集的信息所生成的匿名数据和汇总统计数据进行。</li>
	    </ul>
		（2）百灵威还使用“Cookies”来增强APP和网站的功能、提升用户体验和个性化用户访问。Cookie是一种可在用户访问百灵威APP或网站所使用的电脑或设备上保存的小型文本文件，这些文本文件允许APP和网站存储用户的操作和偏好（例如地理区域），例如，用户将商品存储在购物车中，直到用户下次访问还保留此操作。Cookies取决于浏览器，用户可以将用户使用的浏览器设置为禁用Cookies，以便阻止Cookies被保存在用户的设备上和/或用户可以在访问APP或网站后删除Cookies，由于Cookie的使用遍及整个百灵威APP和网站，因此禁用其中某些Cookie可能会导致您无法使用APP或网站的部分特定内容和功能。<br>
        <br>
		当百灵威打算将以上用户数据用于上述未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，百灵威会事先征求用户的同意。
	<p></p>
	<h6><b>三、信息存储与信息安全</b></h6>
	<p class="mt-lg-1">1. 信息存储</p>
	<p class="mt-lg-1" style="line-height:1.8em; ">
		（1）百灵威在中国境内收集的用户数据，将储存在中国境内的服务器上。<br>
		（2）百灵威会在中国相关法律法规要求的必要期限内、或向用户提供所要求服务的必要期限内、或用户声明同意的期限内存储数据。
	</p>
	<p class="mt-lg-1">2. 信息安全</p>
	<p class="mt-lg-1" style="line-height:1.8em; ">
		（1）百灵威严格遵守相关法律法规，采取业内认可的合理可行的措施，保护用户信息。百灵威遵守所有关于其开展业务的司法管辖区内的数据传输的国家和国际协议。<br>
        （2）百灵威使用合理可行的安全防护措施保护用户信息安全，防止数据遭到未经授权的访问、使用、修改、损坏或丢失等。例如，百灵威使用安全套接字层（SSL）软件协议在传输数据时加密用户的信息；百灵威建立访问控制机制，确保只有授权人员才可以访问用户信息；百灵威定期举办安全和隐私保护培训课程，加强员工对于保护用户信息重要性的认识。<br>
        （3）鉴于互联网环境的复杂性，百灵威会尽力确保用户信息的安全性。一旦发生信息安全事件，百灵威将按照相关法律法规的要求，及时向用户告知：安全事件的基本情况和可能的影响、百灵威已采取或将要采取的处置措施、用户可自主防范和降低风险的建议和对用户的补救措施。百灵威将及时将事件相关情况以邮件等方式告知用户，难以逐一告知时，百灵威将采取合理、有效的方式发布公告。<br>
        （4）百灵威不会出售用户的信息，也不会与任何其他个人或企业共享或披露用户的信息，除非为用户提供用户所要求的产品和服务。这种信息共享可能涉及，但不限于，用户从百灵威订购产品的分发、交付、合作伙伴的沟通。未经用户许可，百灵威不会向任何第三方分享、出售或分发与其客户、消费者或用户有关的任何个人信息。
    </p>
	<h6><b>四、第三方SDK</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; ">
应用中集成了com.ak.torch.shell（奇虎360）、 com.qq.e.ads（广点通；腾讯社交）、com.bytedance.sdk.openadsdk（穿山甲；巨量引擎）、com.qq.e.comm（广点通；腾讯社交）等SDK，是由于百灵威采用的第三方开发工具中集成了这些SDK， 但是本网站和APP中并未使用这些SDK的功能，不会使用这些SDK收集个人信息及其他用途。
        </p>
	<h6><b>五、用户的权力</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; ">
		1、用户可登录用户在百灵威的账户，在账户设置、发票管理页面访问、修正、更新和/或删除用户的信息。或者，用户可以通过以下联系方式或通过联系用户的专属客服随时索取百灵威保存的、与用户有关的信息概要。<br>
                 联系电话：400 666 7788<br>
                 邮箱：jkinfo@jkchemical.com<br>
		2、用户也可以通过以上联系方式要求百灵威依据用户的指示代为操作与本用户相关的以下信息处理：搜索信息、修正信息、更新信息、删除信息或注销账户。百灵威将依据相关法律法规法律及时回应上述要求。<br>
		3、用户有权撤回对使用用户数据的同意，用户可使用上述联系方式向百灵威提出撤回同意的要求。在用户撤回同意之前，所进行的数据处理均合法。<br>
		4、如果用户对百灵威收集和使用用户的信息有任何疑问或异议，请通过上述联系方式与百灵威联系。	
	</p>
	<h6><b>六、用户限制</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; ">百灵威不向18岁以下的人士销售产品或服务。百灵威不会故意收集、使用或透露18岁以下人士的个人数据。</p>
	<h6><b>七、条款修改</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; ">
		1、百灵威有权随时修改本政策的任何内容，一旦本政策的任何内容发生变动，将以APP、网站公示的方式进行公告，而不再单独通知用户，修改后的条款一经公示即代替原来的协议条款。<br>
		2、如果不同意百灵威对本政策所做的修改，用户应停止使用网络服务并通过本政策提供的联系方式联系百灵威。<br>
		3、如果用户继续使用网络服务，则视为用户接受百灵威对本政策所做的修改。
	</p>
	<h6><b>八、本政策自2021年10月26日起适用</b></h6>
</div>`;

export const PrivacyTonva = marked(privacyUI)

export class Privacy extends VPage<CMe>{

    private privacy: any;
    constructor(cMe: CMe) {
        super(cMe);

        makeObservable<Privacy, "privacy">(this, {
            privacy: observable
        });
    }

    async open(param?: any) {

        this.privacy = privacyUI;// await this.controller.getCommonText(1);
        this.openPage(this.page);
    }

    private page = () => {
        return <Page header="隐私政策">
            <div className='bg-white p-3'>
                {/* <div className="mx-auto text-center" style={{ width: 300 }}>
                    <h4>隐私政策</h4>
                    <p>VS.20190920</p>
                </div> */}
                <div className="mt-4" dangerouslySetInnerHTML={{ __html: marked(this.privacy) }} />
            </div>
        </Page>;
    }
}