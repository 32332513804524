import * as React from 'react';
import { VPage, Page, Scroller, LMR, FA, autoHideTips } from "tonva-react";
import { CProduct, siftSearchDefultData } from './CProduct';
import { List } from "tonva-react";
import { observer } from 'mobx-react-lite';
import classNames from 'classnames';
import { makeObservable, observable } from 'mobx';

export class VProductList extends VPage<CProduct> {
    searchLineData: any[] = [];
    brandList: any[] = this.controller.brands.filter((el: any) => el.level === 1000);
    tips = observable.box<any>();
    private searchKey: string;

    constructor(c: CProduct) {
        super(c);
        makeObservable(this, {
            searchLineData: observable,
            brandList: observable,
        });
    }

    async open(key: string) {
        this.searchKey = key;
        this.openPage(this.page);
    }

    private onProductClick = async (product: any) => {
        await this.controller.showProductDetail(product.id);
    }

    private onScrollBottom = async (scroller: Scroller) => {
        scroller.scrollToBottom();
        let { productsPager } = this.controller;
        //await this.controller.pageProducts.more();
        productsPager.more();
    }

    private renderProduct = (p: any) => {
        // console.log(p);
        return this.controller.cApp.cProduct.renderProduct(p);
    }

    private page = observer(() => {

        let { productsPager, cApp } = this.controller;
        let { cHome, cCart } = cApp;
        let header = cHome.renderSearchHeader();
        let cart = cCart.renderCartLabel();
        let none = <div className="p-3 text-warning">[无]</div>;

        return <Page header={header} right={cart} onScrollBottom={this.onScrollBottom}>
            <div className="bg-white py-2 px-3 mb-1">
                <LMR right={<span><button type="button" className="btn btn-sm btn-outline-primary float-right"
                        data-toggle="modal" data-target="#exampleModal">筛选</button></span>} >
                    <small className=" small text-muted">搜索: </small>{this.searchKey}
                </LMR>
                <LMR className="py-1">{this.renderSearchLineData()}</LMR>
            </div>
            {this.renderSiftModal()}
            <List before={''} none={none} items={productsPager} item={{ render: this.renderProduct, onClick: this.onProductClick }} />
        </Page>
    });

    render = (key: any) => {
        this.searchKey = key;
        return <this.page />
    }

    /**
     * 整理筛选条件
     */
    sortSearchLines = () => {
        let arr: any[] = Object.keys(this.controller.searchLines);
        arr.forEach((el: any) => {
            let result: any = this.controller.searchLines[el];
            let [a, b, c, d] = result;
            let lt: string = a, gt: string = b;
            if (el === "amount") { lt = c; gt = d; };
            if (lt !== "" && gt !== "" && (Number(lt) > Number(gt))) { this.controller.searchLines[el] = [gt, lt]; };
        });
        this.searchLineData =  arr.map((el: any) => {
            let result: any[] = this.controller.searchLines[el];
            let obj: any = { brand: "品牌", purity: "纯度", pack: "包装", price: "价格", molecularWeight: "分子量", amount: "总价" };
            let element: any = { name: el, cname: obj[el], data: "" };
            if (el === "brand") {
                let data:any[] = result.filter((i: any) => i).map((i: any) => this.controller.brands.find((j: any) => j.id === i));
                element['data'] = data.length ? data : undefined; //data[data.length - 1]?.name || "";
                this.brandList.forEach((i: any) => {
                    i.isChecked = false;
                    if (data && data.length) {
                        i.isChecked = data.find((j: any) => j.id === i.id) ? true : false;
                    };
                });
            } else if (["purity", "pack", "price", "molecularWeight", "amount"].includes(el)) {
                let unit: string = "";
                let [a, b, c, d] = result;
                let start: string = a, end: string = b;
                let data: string = "";
                let obj1: any = { "purity": "%", "pack": c };
                if (el === "amount") {
                    start = c; end = d;
                    let astr: string = "";
                    if (a !== "") astr = a + b + ((start !== "" || end !== "") ? "/" : "");
                    let bstr: string = (start === "" && end === "") ? "" : [start || 0, end || unit + "以上"].join(end === "" ? '' : '-');
                    data = [astr, bstr].join("");
                } else {
                    unit = obj1[el] || "";
                    if (end !== "") end = end + unit;
                    data = (start === "" && end === "") ? "" : [start || 0, end || unit + "以上"].join(end === "" ? '' : '-');
                };
                element['data'] = data;
            };
            return element;
        });
    }

    actSearch = async () => {
        this.sortSearchLines();
        await this.controller.searchByKey(this.searchKey);
    }

    /**
     * 提交确定筛选条件,并查询
     */
    submitSearchLines = async () => {
        let res: any[] = this.controller.searchLines["amount"];
        let [a, , c, d] = res;
        let arr: any[] = [a, c, d];
        let r: boolean = arr.some((el: any) => el) ? Boolean(a && (c || d)) : true;
        if (!r) {
            this.tips.set("总价查询必须填写总量及价格.");
            return;
        };
        $('#exampleModal').modal('hide');
        await this.actSearch();
    }

    /**
     * 筛选条件  选中
     */
    renderSearchLineData = () => {
        let result: any[] = this.searchLineData.filter((el: any) => el.data);
        let itemOnClick = async (item: any) => {
            let res: number = this.searchLineData.findIndex((el: any) => el.name === item.name);
            if (res > -1) {
                this.controller.searchLines[item.name] = siftSearchDefultData[item.name];
            };
            await this.actSearch();
        };
        /* 确定再次筛选品牌 */
        let brandFinishOnClick = async () => {
            this.controller.brands.forEach((el: any) => {
                let res: any = this.brandList.find((i: any) => i.id === el.id);
                if (res) el.isChecked = res.isChecked;
            });
            let arr: any[] = this.controller.brands.filter((el: any) => el.isChecked).map((el: any) => el.id);
            this.controller.searchLines['brand'] = arr;
            $(".dropdown-toggle").dropdown('hide');
            await this.actSearch();
        };

        /* 回退取消 */
        let brandReset = () => {
            this.brandList = this.controller.brands.filter((el: any) => el.level === 1000);
            $(".dropdown-toggle").dropdown('hide');
        }

        return <>{result.length
            ? <>{
                result.map((el: any) => {
                    let { cname, data } = el;
                    if (el.name === "brand" && data) {
                        data = <>
                            <span className="dropdown-toggle cursor-pointer text-danger" data-toggle="dropdown" aria-expanded="false" data-offset="10,20">
                                {data[0]?.name}
                            </span>
                            <div className="dropdown-menu drop-menu-tr"  onClick={(e: any) => {e.stopPropagation(); e.preventDefault();}} >
                                <div className='text-center row mx-0'>
                                    {this.brandList.map((i: any, index: number) => {
                                        return <span className="col-12 col-sm-6 px-2 my-1"
                                            onClick={() => { this.brandList.splice(index, 1, { ...i, isChecked: !i.isChecked }); }} >
                                            <span className={classNames("badge badge-pill w-100 py-1", i.isChecked && "text-white")}
                                                style={i.isChecked ? { "background": "red ", } : { "background": "#e6e6e6" }}
                                            >{i.name}</span>
                                        </span>
                                    })}
                                </div>
                                <div className='text-center btn-group d-block' >
                                    <button onClick={() => { brandReset(); }} className='btn btn-sm btn-outline-danger'>取消</button>
                                    <button onClick={() => { brandFinishOnClick(); }} className='btn btn-sm btn-danger'>确定</button>
                                </div>
                            </div>
                        </>;
                    };
                    return <span className='float-left py-1' >
                        <small className='border border-primary border-right-0 py-1 px-2 rounded-left' ><b>{cname}: </b>{data}</small>
                        <small onClick={() => { itemOnClick(el) }}
                            className='mr-1 border border-primary py-1 px-2 cursor-pointer rounded-right' >&times;</small>
                    </span>
                })}</>
            : <></>}
        </>;
    };

    /**
     * 筛选弹窗
     */
    renderSiftModal = () => {
        return <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered my-0 ml-auto mr-0 w-75 h-100">
                <div className="modal-content h-100" style={{ background: "#e6e6e6" }}>
                    {this.renderMoreBrand(this.controller.brands)}
                    <div className="modal-body px-0 overflow-auto">
                        {this.controller.renderSearchProductSift(this.searchKey)}
                    </div>
                    <div className="modal-footer p-0 justify-content-center">
                        {autoHideTips(this.tips, <div className='alert alert-danger py-1'>{this.tips.get()}</div>, 50000)}
                        <div className="col-6 mx-0 text-center">
                            <button onClick={() => {
                                this.controller.searchLines = siftSearchDefultData;
                                this.searchLineData = [];
                            }}
                                className='w-75 btn btn-sm btn-outline-primary'>重置</button>
                        </div>
                        <div className="col-6 mx-0 text-center">
                            <button onClick={this.submitSearchLines} className='w-75 btn btn-sm btn-success'>确定</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>;
    }

    /**
     * 更多品牌
     */
    renderMoreBrand = (brands: any[] = []) => {
        let subClick = async () => {
            let arr: any[] = brands.filter((el: any) => el.isChecked).map((el: any) => el.id);
            this.controller.searchLines['brand'] = arr;
            this.submitSearchLines();
            this.controller.isShowMoreBrand = false;
        };

        let left: JSX.Element = <span className='cursor-pointer' onClick={() => { this.controller.isShowMoreBrand = false; }} >
            <FA name="chevron-left" /></span>;

        return <div className='position-absolute w-100 h-100 bg-white'
            style={{ top: 0, right: 0, zIndex: this.controller.isShowMoreBrand ? 100000 : -1 }}>
            <div className='d-flex flex-column h-100' >
                <LMR left={left}
                    className="p-2" style={{ height: 45 }}
                    right={<span onClick={subClick} className='text-primary cursor-pointer' >确定</span>} >
                    <h5 className='text-center' >品牌</h5>
                </LMR>
                <div className="overflow-auto flex-fill">
                    <List items={brands} item={{ render: this.renderItem }} none="无" />
                </div>
            </div>
        </div>;
    }

     renderItem = (item: any, index: number) => {
        let { name, isChecked } = item;
        let isCheckUI: JSX.Element = item.isChecked ? <FA name="check" className="small mr-1 text-danger align-self-center" /> : <></>;
        return <div onClick={() => {
            item.isChecked = !isChecked;
        }} className={classNames('p-2 cursor-pointer ', item.isChecked && "font-weight-bold")} >{isCheckUI} {name }</div>
    }
}