import { CProduct } from './CProduct';
import { VPage, Page } from 'tonva-react';
import { observable, makeObservable } from 'mobx';
import { observer } from 'mobx-react-lite';
import Pdfh5 from 'pdfh5'
import "pdfh5/css/pdfh5.css";

export class VPagePDF extends VPage<CProduct> {
    pdfh5: any;
    fileName: string = '';      /* 文件名称 暂弃 */
    fileUrl: any;               /* 文件路径 */
    isFoundFile: boolean = true;/* 文件是否存在 */
    fileContent: any;           /* 内容 */

    constructor(c: CProduct) {
        super(c);

        makeObservable(this, {
            pdfh5: observable,
            fileName: observable,
            fileUrl: observable,
            isFoundFile: observable,
            fileContent: observable
        });
    }

    async open(param?: any) {
        // this.fileName = this.controller.currentFileName;
        this.fileUrl = param?.fileUrl || '#';
        this.fileContent = param?.content;
        setTimeout(() => {this.parsRenderPDF()}, 50);
        this.openPage(this.page);
    }

    private parsRenderPDF = () => {
        if (!this.fileUrl) {
            this.isFoundFile = false;
        } else {
            this.isFoundFile = true;
            this.pdfh5 = new Pdfh5("#PDFVIEW", {
                // pdfurl: this.fileUrl
                data: this.fileContent
            });
        }
    }

    private page = observer(() => {
        return <Page >
            <div className="text-right mt-1 rounded py-1 pr-3 d-none d-sm-block" style={{background:'#f5f5f5'}}>
                <a href={this.fileUrl} target="_blank" rel="noreferrer">
                    <button className="btn btn-sm btn-success w-3c" style={{backgroundColor: '#218838'}}>下载</button>
                </a>
            </div>
            {
                this.isFoundFile
                    ? <div id="PDFVIEW" className="w-100 my-2" style={{ height: '100vh',overflowY:'auto' }}></div>
                    : <div className="text-center py-5">NOT FOUND</div>
            }
        </Page>
    })
}