import * as React from 'react';
import { makeObservable, observable } from 'mobx';
import { observer } from 'mobx-react';
import { VPage, Page, UiSchema, UiInputItem, Form, Context, tv, BoxId, FA } from "tonva-react";
import { Schema } from "tonva-react";
import { CInvoiceInfo } from './CInvoiceInfo';

const schema: Schema = [
    { name: 'id', type: 'id', required: false },
    { name: 'title', type: 'string', required: false },
    { name: 'taxNo', type: 'string', required: false },
    { name: 'address', type: 'id', required: false },
    { name: 'telephone', type: 'string', required: false },
    { name: 'bank', type: 'string', required: false },
    { name: 'accountNo', type: 'string', required: false },
];

const commonRequired = {
    id: false,
    title: true,
    taxNo: true,
    address: false,
    telephone: false,
    bank: false,
    accountNo: false,
    isDefault: false,
};

const valueAddedRequired = {
    id: false,
    title: true,
    taxNo: true,
    address: true,
    telephone: true,
    bank: true,
    accountNo: true,
    isDefault: false,
}

const commonVisible = {
    id: false,
    title: true,
    taxNo: true,
    address: false,
    telephone: false,
    bank: false,
    accountNo: false,
    isDefault: true,
};

const valueAddedVisible = {
    id: false,
    title: true,
    taxNo: true,
    address: true,
    telephone: true,
    bank: true,
    accountNo: true,
    isDefault: true,
}

const commonRequired1 = {
    id: false,
    title: true,
    taxNo: false,
    address: false,
    telephone: false,
    bank: false,
    accountNo: false,
    isDefault: false,
};

const commonVisible1 = {
    id: false,
    title: true,
    taxNo: false,
    address: false,
    telephone: false,
    bank: false,
    accountNo: false,
    isDefault: false,
};

export enum PENUMInvoiceType {
    common = 1,
    valueAdded = 2,
    noInvoice = 3,
    personal = 4,
    eUniversal = 5,
    eSpecial = 6,
};

const FormOpt: { [key: number]: any } = {
    [PENUMInvoiceType.common]: { require: commonRequired, visible: commonVisible },
    [PENUMInvoiceType.valueAdded]: { require: valueAddedRequired, visible: valueAddedVisible },
    [PENUMInvoiceType.personal]: { require: commonRequired1, visible: commonVisible1 },
    [PENUMInvoiceType.eUniversal]: { require: commonRequired, visible: commonVisible },
    [PENUMInvoiceType.eSpecial]: { require: valueAddedRequired, visible: valueAddedVisible },
};

export class VInvoiceInfo extends VPage<CInvoiceInfo> {
    private form: Form;
    showTip: boolean = false;
    saveTip: string = "";
    private invoiceInfoData: any;

    uiSchema: UiSchema = {
        items: {
            id: { visible: false },
            title: {
                widget: 'text', label: '单位全称', placeholder: '必填',
                rules: (value: string) => {
                    return (value && value.length > 200) ? '单位全称最多200个字！' : undefined;
                }
            } as UiInputItem,
            taxNo: {
                widget: 'text', label: '纳税人识别码', placeholder: '必填',
                rules: (value: string) => {
                    if (value) {
                        value = value.trim();
                        var regArr = [/^[\da-z]{10,15}$/i, /^\d{6}[\da-z]{10,12}$/i, /^[a-z]\d{6}[\da-z]{9,11}$/i, /^[a-z]{2}\d{6}[\da-z]{8,10}$/i, /^\d{14}[\dx][\da-z]{4,5}$/i, /^\d{17}[\dx][\da-z]{1,2}$/i, /^[a-z]\d{14}[\dx][\da-z]{3,4}$/i, /^[a-z]\d{17}[\dx][\da-z]{0,1}$/i, /^[\d]{6}[\da-z]{13,14}$/i],
                            j = regArr.length;
                        for (var i = 0; i < j; i++) {
                            if (regArr[i].test(value)) {
                                return undefined;
                            }
                        }
                        return '纳税人识别码格式不正确，请重新输入！';
                    }
                }
            } as UiInputItem,
            address: {
                widget: 'text', label: '注册地址', placeholder: '必填',
                rules: (value: string) => {
                    return (value && value.length > 200) ? '注册地址最多200个字！' : undefined;
                }
            } as UiInputItem,
            telephone: {
                widget: 'text', label: '注册电话', placeholder: '必填',
                rules: (value: string) => {
                    if (value) value = value.trim();
                    if (value && !/^(\(\d{3,4}\)|\d{3,4}-|\s)?\d{7,14}$/.test(value))
                        return "注册电话格式不正确，请重新输入！";
                    else
                        return undefined;
                }
            } as UiInputItem,
            bank: {
                widget: 'text', label: '开户银行', placeholder: '必填',
                rules: (value: string) => {
                    return (value && value.length > 100) ? '开户银行名称最多100个字！' : undefined;
                }
            } as UiInputItem,
            accountNo: {
                widget: 'text', label: '银行账号', placeholder: '必填',
                rules: (value: string) => {
                    if (value) value = value.trim();
                    if (value && !/^\d{8,30}$/.test(value.replace(/\s*/g, "")))
                        // if (value && !/^([1-9]{1})(\d{10,29})$/.test(value.replace(/\s*/g, "")))
                        return "银行账号格式不正确，请重新输入！";
                    else
                        return undefined;
                }
            } as UiInputItem,
            submit: { widget: 'button', label: '提交' },
        }
    }

    constructor(c: CInvoiceInfo) {
        super(c);
        makeObservable(this, {
            showTip: observable,
            invoiceType: observable
        });
    }

    async open(origInvoice?: any) {
        let { invoiceType, invoiceInfo } = origInvoice;
        this.invoiceType = (invoiceType && invoiceType.id) || 5;
        if (invoiceInfo) {
            invoiceInfo.assure();
            this.invoiceInfoData = { ...invoiceInfo.obj };
        } else {
            this.invoiceInfoData = { 'title': this.controller.cApp.currentUser.defaultOrganizationName };
        };
        (this.uiSchema.items.title as any).label =
            this.invoiceType === PENUMInvoiceType.personal ? "发票抬头" : "单位全称";
        this.openPage(this.page);
    }

    private onFormButtonClick = async (name: string, context: Context) => {
        let { form } = context;
        let { data } = form;
        let invoice = {
            invoiceType: this.invoiceType,
            invoiceInfo: data,
        };
        this.invoiceInfoData = data;

        try {
            await this.controller.saveInvoiceInfo(invoice);
            this.saveTip = "发票信息已经保存";
        } catch (error) {
            this.saveTip = "发票信息保存失败，请稍后再试";
        }
        this.showTip = true;
        setTimeout(() => { this.showTip = false; }, 2000);
    }

    private onSaveInvoice = async () => {
        if (!this.form) return;
        await this.form.buttonClick("submit");
    }

    invoiceType: number;

    private buildForm(): JSX.Element {
        // let requiredFields: any = this.invoiceType === 1 ? commonRequired : valueAddedRequired;
        // let visibleFields: any = this.invoiceType === 1 ? commonVisible : valueAddedVisible;
        let requiredFields: any = FormOpt[this.invoiceType]?.require || valueAddedRequired;
        let visibleFields: any = FormOpt[this.invoiceType]?.visible || valueAddedVisible;
        schema.forEach(e => {
            let { items } = this.uiSchema;
            e.required = requiredFields[e.name];
            items[e.name].visible = visibleFields[e.name];
        });
        /* return <Form ref={v => this.form = v} className="my-3"
            schema={schema}
            uiSchema={uiSchema}
            formData={this.invoiceInfoData}
            onButtonClick={this.onFormButtonClick}
            fieldLabelSize={3} /> */
        return React.createElement(observer(() => {
            return <Form ref={v => this.form = v} className="my-3"
                schema={schema}
                uiSchema={this.uiSchema}
                formData={this.invoiceInfoData}
                onButtonClick={this.onFormButtonClick}
                fieldLabelSize={3} />
        }))
    }

    private onInvoiceTypeClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.invoiceType = parseInt(event.currentTarget.value);
        (this.uiSchema.items.title as any).label =
            this.invoiceType === PENUMInvoiceType.personal ? "发票抬头" : "单位全称";
    }

    private page = observer(():any => {
        let frm = this.buildForm();

        let tipUI = this.showTip ? (<div className="alert alert-primary" role="alert">
            <FA name="exclamation-circle" className="text-warning float-left mr-3" size="2x"></FA>
            {this.saveTip}
        </div>) : null;
        let isDisabled:boolean = this.controller.isPerInvoice ? this.controller.cApp.cOrder.payer : false;
        return <Page header="发票">
            <div className="px-3">
                <div className="form-group row py-3 mb-1 bg-white">
                    <div className="col-12 col-sm-3 pb-2 text-muted">发票类型:</div>
                    <div className="col-12 col-sm-9">
                        {[
                            { name: "电子发票（普通发票）", htmlFor: "eUniversal", value: PENUMInvoiceType.eUniversal, disabled: false },
                            { name: "电子发票（增值税专用发票）", htmlFor: "eSpecial", value: PENUMInvoiceType.eSpecial, disabled: false },
                            { name: "个人", htmlFor: "personal", value: PENUMInvoiceType.personal, disabled: isDisabled || false },
                        ].map((el: any) => {
                            let { name, htmlFor, value, disabled } = el;
                            return <div key={name} className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="invoiceType"
                                    id={htmlFor} value={value} disabled={disabled} checked={this.invoiceType === value}
                                    onChange={(event) => this.onInvoiceTypeClick(event)}></input>
                                <label className="form-check-label" htmlFor={htmlFor}>{name}</label>
                            </div>
                        })}
                    </div>
                </div>
            </div>
            <div className="p-3 bg-white">
                {frm}
                <button type="button"
                    className="btn btn-primary w-100"
                    onClick={this.onSaveInvoice}>确定</button>
                {tipUI}
            </div>
        </Page>
    });
}
