//=== UqApp builder created on Tue Jan 12 2021 23:14:51 GMT-0500 (GMT-05:00) ===//
import { GLOABLE } from "cartenv";
import { jnkTop } from "me/loginTop";
import { AppConfig, DevConfig } from "tonva-react";
import { tvs } from "tvs";
import { PrivacyTonva } from 'me/privacy';

const bz: DevConfig = {
    name: 'bizdev',
    alias: 'bz',
}

const jk: DevConfig = {
    name: '百灵威系统工程部',
    alias: 'jk',
}

export const appConfig: AppConfig = {
    version: '1.2.21',
    app: {
        dev: jk,
        name: 'website',
    },
    uqs: [
        /*
        {
            dev: bz,
            name: 'hello-tonva',
            alias: 'HelloTonva',
            version: '0.1.0',
        },
        */

        {
            dev: jk,
            name: 'order',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'product',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'customer',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'coupon',
            alias: 'coupon',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'common',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'webuser',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'customerDiscount',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'promotion',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'warehouse',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'salesTask',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'member',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: '积分商城',
            alias: 'Pointshop',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'webBuilder',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'orderDraft',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'platFormJoint',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'deliver',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'chemical',
            version: '0.1.1',
        },
        {
            dev: jk,
            name: 'chemicalSecurity',
            version: '0.1.1',
        },
    ],
    noUnit: true,
    tvs,
    oem: undefined,
    loginTop: jnkTop,
    privacy: PrivacyTonva //GLOABLE.PIRVACYURL
};
