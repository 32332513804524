import * as React from 'react';
import { CProduct } from './CProduct';
import { VPage, Page } from "tonva-react";
import { tv } from "tonva-react";
import { productPropIsValid } from 'product';
import { Carousel } from 'antd';
import { CustomFooter, CustomHeader } from 'home/VHomeCustom';
import { observer } from 'mobx-react';

export class VProductCustom extends VPage<CProduct> {
    productData: any = {};

    async open(param: any) {
        let { productData } = param;
        this.productData = productData;
        this.openPage(this.page, productData);
    }

    private renderProduct = (product: any) => {
        let { main, subs } = product;
        let { id, brand, description, descriptionC, CAS, purity, molecularFomula, molecularWeight, origin, imageUrl, gradeCN } = main;
        let packUI: any = subs && subs.length ? <> {
            subs.slice(0, 3).map((el: any) =>
                <span className='mx-2 font-weight-bold fs-12 d-inline-block text-center'
                    style={{ width:"58px", height:"24px", borderRadius: "12px", padding:"0px 4px", backgroundColor: "#D8D8D8" }}
                ><span className='align-middle'>{tv(el?.pack)}</span></span>)}
        </> : <></>;

        let click = async () => {
            await this.controller.showProductDetail(id, false, true);
            //  window.location.href = `?type=product&product=${id}`
        }

        return <div className='cursor-pointer' onClick={() => { click() }} >
            <div className='card bg-light mb-1 border-0' style={{ padding: "8px 16px" }}>
                <div className='font-weight-bolder'>
                    {descriptionC}{productPropIsValid(purity) ? " , " + purity : ""}{productPropIsValid(gradeCN) ? " , " + gradeCN : ""}
                </div>
                <div className='fs-12' style={{ color: "#282828" }} >{description}</div>
            </div>
            <div className='card p-16 bg-light my-1 border-0 fs-12'>
                <div><span className='fs-12 align-middle' style={{ color: "#686868" }} >规格</span> {packUI}</div>
            </div>
            <div className='card p-16 bg-light my-1 border-0 fs-12' >
                <div className='row mx-0 main-row-1'>
                    <div className='col-3 col-md-2 px-0 font-weight-bold'>产品编号 </div>
                    <div className='col-4 col-md-2 px-0 color-lightBlack'>{origin}</div>
                    <div className='col-3 col-md-1 px-0 font-weight-bold'>C A S </div>
                    <div className='col-4 col-md-3 px-0 color-lightBlack'>{CAS}</div>
                    <div className='col-3 col-md-1 px-0 font-weight-bold'>纯&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;度 </div>
                    <div className='col-4 col-md-3 px-0 color-lightBlack'>{purity}</div>
                    <div className='col-3 col-md-2 px-0 font-weight-bold'>分子式 </div>
                    <div className='col-4 col-md-2 px-0 color-lightBlack'>{molecularFomula}</div>
                    <div className='col-3 col-md-1 px-0 font-weight-bold'>分 子 量 </div>
                    <div className='col-4 col-md-3 px-0 color-lightBlack'>{molecularWeight}</div>
                    <div className='col-3 col-md-1 px-0 font-weight-bold'>品&nbsp;&nbsp;&nbsp;牌</div>
                    <div className='col-4 col-md-3 px-0 color-lightBlack'>{brand?.name}</div>
                </div>
            </div>
        </div>;
    }

    private page = observer((product: any):any => {
        let { cApp } = this.controller;
        let { main } = product;
        let { cHome } = cApp;
        return <Page header={false} footer={CustomFooter}>
            <div className='bg-f234' >
                <div className='position-sticky' style={{ top: 0, zIndex: 16 }}>
                    {CustomHeader}
                </div>
                <Carousel effect="fade" /* autoplay */ dots={{ className: "carouselDots" }} >
                    {cHome.banners.map((el: any) => {
                        return <div>
                            <img src={el.path} className="d-block w-100 m-0 text-center bg-light main-custom-slider" alt="..."  ></img>
                        </div>
                    })}
                </Carousel>
                <div className='p-16' >
                    {this.renderProduct(product)}
                    {this.controller.tabProductMaterialView({ origin: main?.origin })}
                    <div className='text-center fs-12' style={{ color: "#686868", paddingTop: "16px" }} >
                        <span className='cursor-pointer' onClick={() => { this.closePage(); }} >
                            <i className="fa fa-chevron-left mr-1" aria-hidden="true" /> <span>返回搜索</span>
                        </span>
                    </div>
                </div>

            </div>
        </Page>
    });
}