import * as React from 'react';
import { Page, VPage } from "tonva-react";
import marked from 'marked';
import { CMe } from './CMe';
import { makeObservable, observable } from 'mobx';
const legalC: any = `<div class="container">
    <p class="mt-lg-1" style="line-height:1.8em; "> 北京百灵威科技有限公司（以下简称“本公司”）提供本网站服务。用户在登录本网站后，请仔细阅读本协议，如您不 同意有关条款或本网站对其进行的修改，请停止使用本网站提供的服务。您一旦使用本网站提供的内容，包括但不限于浏览网站信息、下载网站内容、使用网站提供 的第三方网站链接等，即视为您已了解并完全同意本协议中的所有条款。</p>
	<h6><b>一、网站使用</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 除非相关法律法规许可或征得本公司同意，本网站的信息及其任何组成部分不得被重新编辑、复制、仿制、抄袭，或为任何未经本公司允许的商业目的所使用。如果本公司确定客户行为违法或有损本网站和本公司的合法利益，则本公司保留包括但不限于拒绝提供服务、冻结或删除会员帐户、采取相关法律措施的权利。</p>
	<h6><b>二、链接</b></h6>
	<h6>（一）本网站对第三方网站的链接</h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 必要时，本网站将提供对第三方经营的其他网站的超级链接或指引服务，或者通过帧联及其他方式在本网站上提供第三方网站的内容，此“内容”包括但不限于本网站上包含或可获得的任何材料、文件、信息、图片、图表、标识、设计以及本网站上提供的任何其他信息。</p>
	<p class="mt-lg-1" style="line-height:1.8em; ">对第三方网站的链接仅为供您方便之用。本公司保留在任何时间终止对第三方网站链接的权利。所链接网站、网站上的内容及其描述或提供的产品与服务均不受本公司的控制，因此本公司不对任何该等网站及其上的内容、产品或服务（包括对第三方网站的进一步链接）负责。您一旦决定登录或使用任何所链接的网站，应当注意可能会受到适用于该网站的任何条款和条件的限制，同时自行采取预防措施以便在登录或使用该网站时避免病毒或其他破坏性因素。对于您登录或使用所链接网站所产生的任何损失，由您自行承担。</p>
	<p class="mt-lg-1" style="line-height:1.8em; "> 本公司对于任何所链接网站或其上所显示的内容、描述或提供的任何产品或服务不作任何保证或陈述，并且不对其作出任何认可、建议、批准或介绍。除非本公司另行明确规定或同意，本公司将不是您与任何被链接网站的提供者之间达成的任何协议的当事方。本公司不对因任何被链接网站上提供的内容、产品、服务等存在的任 何中断、延迟、漏洞或疏忽所导致的任何损害或损失（无论该损害或损失是直接、间接、特别或是偶然的）负责。本网站上出现对第三方网站的链接并不表示本公司 或本网站与其相关联，或已获得合法授权使用被链接网站中显示的或可通过其链接到的任何商标、商号、标识或著作权。</p>
	<h6>（二）第三方网站对本网站的链接</h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 第三方网站对本网站的链接，并不必然表示本公司对第三方作出了某种认可、授权、赞助，或表明其属于本公司的关联企业、合资企业、合作企业或业务伙伴。在多数情况下，本公司并未意识到第三方已提供对本网站的链接。</p>
	<h6><b>三、知识产权保护</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 本网站所载述的域名、商标、商号、信息、文字、图形、图像、声音、链接、软件及所有其它内容均由本公司、其内容提供商及第三方许可人提供。上述内容的知识产权依具体情况分别为本公司、其内容提供商及第三方许可人所拥有。除法律另有规定或双方另有约定外，未经本公司和/或其他权利人事先许可，任何人不得以任何形式通过任何方式（包括但不限于电子、机械复印或录制等方式）复制、展示、修改、转让、分发、重新发布、下载、张贴或传输本网站的内容。未经本公司或商标权人事先许可，任何单位及个人不得以任何方式或理由对本网站的商标、商号或标识的任何部分进行使用、复制、修改、传播或与其它产品捆绑使用。</p>
	<h6><b>四、免责声明</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 本网站所提供的信息，具备一定的准确性、有效性和完整性，但因数据庞大，且受数据来源的不确定性影响，不能保证绝对的准确性、有效性和完整性。本公司及其员工一概毋须以任何方式就任何信息传递或传送的失误、不准确或错误对用户或任何其他人士负任何直接或间接的责任。在法律允许的范围内，本公司不承担用户或任何人士就使用或未能使用本网站所提供的信息或任何链接或项目所引致的任何直接、间接、附带、从属、特殊、惩罚性的损害赔偿（包括但不限于收益、预期利润的损失或失去的业务、未实现预期的节减）。本公司保留自行决定更正本网站中任何部分的任何错误或遗漏的权利。本公司可以在任何时间对本网站、本网站上描述的内容和产品、项目、服务或价格作出任何其他修改而无需通知。</p>
	<p class="mt-lg-1" style="line-height:1.8em; "> 本网站、本网站上的信息和内容以及在本网站上可获得的软件和材料均“依原样”提供而不包含明示或暗示的任何性质的陈述或保证。在法律许可的最大限度内，本公司声明不作出任何明示或暗示的陈述和保证，包括但不限于有关安全性、准确性、完整性、适销性、无侵权、适合任何特别目的或没有计算机病毒的保证。您应为使用本网站承担全部责任和风险。</p>
	<p class="mt-lg-1" style="line-height:1.8em; "> 如发生以下情况，本网站不对用户的直接或间接损失承担法律责任：</p>
	<ol class="px-2 mx-2" style="line-height:1.8em; "> 
		<li>营业中断。</li>
		<li>无法登录本网站，或登录本网站延迟或中断。</li>
		<li>数据无法传送、错误传送、毁损、灭失或其他修改。</li>
		<li>因处理本网站上对其它网站的链接或本网站上存在对其它网站的链接所遭受的任何种类的损失或损害。</li>
		<li>就客户使用本网站（包括链接到第三方网站或自第三方网站链接）而可能产生的计算机病毒、系统失灵或功能紊乱。</li>
		<li>本《隐私政策》第四条中规定的六种情形所引起的用户个人资料的披露或泄露。</li>
		<li>因不可抗力或本公司无法合理控制的其他原因所导致的用户损失。</li>
	</ol>
	<h6><b>五、服务变更、中断或终止</b></h6>
	<ol class="px-2 mx-2" style="line-height:1.8em; "> 
		<li>如因系统维护或升级的需要而需暂停网络服务，百灵威将尽可能事先进行通告。</li>
		<li>如发生下列任何一种情形，百灵威有权随时中断或终止向用户提供本协议项下的网络服务而无需通知用户：（a）用户提供的资料不真实；（b）用户违反本协议中规定的使用规则。</li>
		<li>除前款所述情形外，百灵威同时保留在不事先通知用户的情况下随时中断或终止部分或全部网络服务的权利，对于所有服务的中断或终止而造成的任何损失，百灵威无需对用户或任何第三方承担任何责任。</li>
		<li>用户若反对任何服务条款的建议或对后来的条款修改有异议，或对网络服务不满，用户只有以下追索权：（a）不再使用百灵威提供的网络服务。（b）通告百灵威停止使用百灵威的网络服务，得到百灵威确认后，用户使用网络服务的权利马上中止。从即时起，百灵威不再对用户承担任何义务。</li>
	</ol>
	<h6><b>六、隐私</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; "> 百灵威的<a class="text-primary" href="https://www.jkchemical.com/privacy-cn"><u>隐私政策</u></a>适用于本网站的使用。使用本网站，即表示您确认并同意百灵威可以根据此隐私政策使用此类信息。</p>
	<h6><b>七、条款修改</b></h6>
	<p class="mt-lg-1" style="line-height:1.8em; ">本公司保留在任何时间自行修改、增删本协议中任何内容的权利。您每次登录或使用本网站时均视为同意受当时有效的条款的制约。因此，您每次登录或使用本网站时应查看本协议的日期，并检查与上一版本的修改之处。</p>
</div>`;
export class VLegal extends VPage<CMe>{

	private legal: any;

	constructor(cMe: CMe) {
        super(cMe);

        makeObservable<VLegal, "legal">(this, {
            legal: observable
        });
	}

    async open(param?: any) {

        this.legal = legalC;
        this.openPage(this.page);
    }

    private page = () => {
        return <Page header="百灵威购物用户协议">
            <div className='bg-white p-3'>
                <div className="mx-auto text-center" style={{ width: 300 }}>
                    <h4>用户协议</h4>
                    <p>VS.20210225</p>
                </div>
                <div className="mt-4" dangerouslySetInnerHTML={{ __html: marked(this.legal) }} />
            </div>
        </Page>;
    }
}