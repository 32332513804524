import { observable, makeObservable, computed } from 'mobx';
import { BoxId, QueryPager, Tuid } from "tonva-react";
import { nav } from "tonva-react";
import { CUqBase } from '../CBase';
import { VCreateOrder } from './VCreateOrder';
import { Order, OrderItem } from './Order';
import { OrderSuccess } from './OrderSuccess';
import { CSelectShippingContact, CSelectInvoiceContact } from '../customer/CSelectContact';
import { VMyOrders } from './VMyOrders';
import { VOrderDetail } from './VOrderDetail';
import { CInvoiceInfo } from '../customer/CInvoiceInfo';
import { groupByProduct, groupByProduct1 } from '../tools/groupByProduct';
import { CartItem2, CartPackRow } from '../cart/Cart';
import { createOrderPriceStrategy, OrderPriceStrategy } from 'coupon/Coupon';
import { CApp } from 'CApp';
import moment from 'moment';
import { OrderDetail, OrderMain, OrderMainEx } from 'uq-app/uqs/JkOrder';
import _ from 'lodash';
import { EnumCouponType } from 'uq-app/uqs/JkCoupon';
import { GLOABLE } from 'cartenv';
import { VOrderTrans } from './VOrderTrans';
import { appApis } from 'centerApi';

const FREIGHTFEEFIXED = 12;
const FREIGHTFEEREMITTEDSTARTPOINT = 100;

export enum ENUMPaymentType {
    orgPay = 1,
    perPay = 2,
};

export enum ENUMOrderB2BC {
    B2B = 1,
    B2C = 2,
};


export class COrder extends CUqBase {
    orderData: Order = new Order();
    /**
     * 存储已经被应用的卡券，以便在使用后（下单后）将其删除
     */
    couponAppliedData: any = {};
    hasAnyCoupon: boolean;
    /**
     * 当前webuser对应的buyeraccount，用来设置订单中的buyeraccount
     */
    buyerAccounts: any[] = [];
    exOrderContacts: any;

    paymentType: number = ENUMPaymentType.orgPay;/* 单位1 个人2 */

    constructor(cApp: CApp) {
        super(cApp);

        makeObservable(this, {
            orderData: observable,
            couponAppliedData: observable,
            buyerAccounts: observable,
            exOrderContacts: observable,
            paymentType: observable,
            payer: computed
        });
    }

    get payer() {
        return this.paymentType === ENUMPaymentType.orgPay;
    }

    protected async internalStart(param: any) {
    }

    renderOrderDraft = async (param: any) => {
        let { orderData, orderDraftBrief } = param
        let { cCoupon } = this.cApp;
        this.orderData = orderData;
        let { sharedCouponValidationResult } = cCoupon;
        if (sharedCouponValidationResult) {
            let { result, types, id } = sharedCouponValidationResult;
            if (result === 1) {
                if (types === "vipcard" || types === "coupon") {
                    sharedCouponValidationResult.discountSetting = await cCoupon.getValidDiscounts(types, id);
                }
                this.applyCoupon(sharedCouponValidationResult);
            }
        }
        this.openVPage(VCreateOrder, { fromOrderParam: "fromOrderDraft", orderDraftBrief });
    }

    initInvoiceData = async () => {
        if (this.orderData.invoiceContact === undefined) {
            this.orderData.invoiceContact = await this.getDefaultInvoiceContact();
        };
        if (this.orderData.invoiceType === undefined || this.orderData.invoiceType?.id === 3) {
            this.orderData.invoiceType = await this.getDefaultInvoiceType();
        }

        if (this.orderData.invoiceInfo === undefined) {
            this.orderData.invoiceInfo = await this.getDefaultInvoiceInfo();
        }
    }

    createOrderFromCart = async (cartItems: CartItem2[]) => {
        let { cApp, uqs } = this;
        let { currentUser, currentSalesRegion, cCoupon, cProduct } = cApp;
        this.orderData.webUser = currentUser.id;
        this.orderData.salesRegion = currentSalesRegion.id;
        this.removeCoupon();
        this.hasAnyCoupon = await this.hasCoupons();

        let buyerAccountQResult = await uqs.webuser.WebUserBuyerAccount.query({ webUser: currentUser.id })
        if (buyerAccountQResult) {
            this.buyerAccounts = buyerAccountQResult.ret;
            if (this.buyerAccounts && this.buyerAccounts.length === 1) {
                this.orderData.customer = this.buyerAccounts[0].buyerAccount;
            }
        }

        if (this.orderData.shippingContact === undefined) {
            this.orderData.shippingContact = await this.getDefaultShippingContact();
        }

        if (this.orderData.invoiceContact === undefined) {
            this.orderData.invoiceContact = await this.getDefaultInvoiceContact();
        }

        if (this.orderData.invoiceType === undefined) {
            this.orderData.invoiceType = await this.getDefaultInvoiceType();
        }

        if (this.orderData.invoiceInfo === undefined) {
            this.orderData.invoiceInfo = await this.getDefaultInvoiceInfo();
        }

        if (cartItems !== undefined && cartItems.length > 0) {
            this.orderData.currency = cartItems[0].packs[0].currency;
            let promise: PromiseLike<any>[] = [];
            this.orderData.orderItems = cartItems.map((e: any) => {
                var item = new OrderItem();
                item.product = e.product;
                item.packs = e.packs.map((v: any) => { return { ...v } }).filter((v: any) => v.quantity > 0 && v.price);
                item.packs.forEach((pk) => {
                    pk.priceInit = pk.price;
                });
                promise.push(cProduct.getProduct2c(item.product?.id));
                return item;
            });
            let result: any[] = await Promise.all(promise);
            let isProduct2C: boolean = result.every((el: any) => el);
            this.orderData.order2BC = isProduct2C ? ENUMOrderB2BC.B2C : ENUMOrderB2BC.B2B;

            // 运费和运费减免
            this.orderData.freightFee = FREIGHTFEEFIXED;
            if (this.orderData.productAmount > FREIGHTFEEREMITTEDSTARTPOINT)
                this.orderData.freightFeeRemitted = FREIGHTFEEFIXED * -1;
            else
                this.orderData.freightFeeRemitted = 0;
        };
        if (currentUser.customerAttr && currentUser.customerAttr === ENUMOrderB2BC.B2C) {
            this.paymentType = ENUMPaymentType.perPay;
        };

        /*
        let currentCode = currentCouponCode || currentCreditCode;
        if (currentCode) {
            let coupon = await cCoupon.getCouponValidationResult(currentCode);
            if (coupon.result === 1)
                this.applyCoupon(coupon);
            else {
                this.cApp.currentCouponCode = undefined;
                this.cApp.currentCreditCode = undefined;
            }
        }
        */
        // 如果当前webuser有VIP卡，默认下单时使用其VIP卡
        let { webUserVIPCard } = currentUser;
        if (webUserVIPCard !== undefined) {
            let coupon = await cCoupon.getCouponValidationResult(
                webUserVIPCard.vipCardCode.toString()
            );
            let { result, types, id } = coupon;
            if (result === 1) {
                if (types === "vipcard" || types === "coupon") {
                    coupon.discountSetting = await cCoupon.getValidDiscounts(types, id);
                }
                this.applyCoupon(coupon);
            }
        }
        this.openVPage(VCreateOrder, { fromOrderParam: "fromCart" });
    }

    private defaultSetting: any;
    private async getDefaultSetting() {
        if (this.defaultSetting) return this.defaultSetting;
        let { currentUser } = this.cApp;
        return this.defaultSetting = await currentUser.getSetting() || {};
    }

    private contact0: BoxId;
    private async getContact(): Promise<BoxId> {
        if (this.contact0 === null) return;
        if (this.contact0 !== undefined) return this.contact0;
        let { currentUser } = this.cApp;
        let contactArr = await currentUser.getContacts();
        if (contactArr === undefined || contactArr.length === 0) {
            this.contact0 = null;
            return;
        }
        return this.contact0 = contactArr[0].contact;
    }

    private async getDefaultShippingContact() {
        let defaultSetting = await this.getDefaultSetting();
        return defaultSetting.shippingContact || await this.getContact();
    }

    private async getDefaultInvoiceContact() {
        let defaultSetting = await this.getDefaultSetting();
        return defaultSetting.invoiceContact || await this.getContact();
    }

    private async getDefaultInvoiceType() {
        let defaultSetting = await this.getDefaultSetting();
        return defaultSetting.invoiceType;
    }

    private async getDefaultInvoiceInfo() {
        let defaultSetting = await this.getDefaultSetting();
        return defaultSetting.invoiceInfo;
    }

    /**
     * 提交订单
     */
    submitOrder = async (orderDraftId: any) => {
        let { uqs, cart, currentUser } = this.cApp;
        let { order, webuser, customer, orderDraft } = uqs;
        let { orderItems } = this.orderData;

        let result: any = await order.Order.save("order", this.orderData.getDataForSave());
        let { id: orderId, flow, state, no } = result;
        await order.Order.action(orderId, flow, state, "submit");

        // 如果使用了coupon/credits，需要将其标记为已使用
        let { id: couponId, code, types } = this.couponAppliedData;
        if (couponId) {
            let usedDate = moment().format("YYYY-MM-DD HH:mm:ss");
            // let nowDate = new Date();
            // let usedDate = `${nowDate.getFullYear()}-${nowDate.getMonth() + 1}-${nowDate.getDate()}`;
            switch (types) {
                case 'coupon':
                    if (currentUser.hasCustomer) {
                        let customerId = currentUser.currentCustomer.id;
                        await this.uqs.JkCustomer.AcceptCustomerCouponUsed.submit({ customer: customerId, coupon: couponId });
                        // customer.CustomerCoupon.del({ customer: customerId, coupon: couponId, arr1: [{ couponType: 1 }] });
                        // customer.CustomerCouponUsed.add({ customer: customerId, arr1: [{ coupon: couponId, usedDate: usedDate }] });
                    } else {
                        await this.uqs.JkWebuser.AcceptWebUserCouponUsed.submit({ webUser: currentUser.id, coupon: couponId });
                        // webuser.WebUserCoupon.del({ webUser: currentUser.id, coupon: couponId, arr1: [{ couponType: 1 }] });
                        // webuser.WebUserCouponUsed.add({ webUser: currentUser.id, arr1: [{ coupon: couponId, usedDate: usedDate }] });
                    };
                    break;
                case 'credits':
                    if (currentUser.hasCustomer) {
                        let customerId = currentUser.currentCustomer.id;
                        await this.uqs.JkCustomer.AcceptCustomerCreditsUsed.submit({ customer: customerId, coupon: couponId, orderItemId: no });
                        // customer.CustomerCredits.del({ customer: customerId, arr1: [{ credits: couponId }] });
                        // customer.CustomerCreditsUsed.add({ customer: customerId, credits: couponId, arr1: [{ saleOrderItem: no, usedDate: usedDate }] });
                    } else {
                        await this.uqs.JkWebuser.AcceptWebUserCreditsUsed.submit({ webUser: currentUser.id, coupon: couponId, orderId: no });
                        // webuser.WebUserCredits.del({ webUser: currentUser.id, arr1: [{ credits: couponId }] });
                        // webuser.WebUserCreditsUsed.add({ webUser: currentUser.id, credits: couponId, arr1: [{ saleOrder: no, usedDate: usedDate }] });
                    };
                    break;
                default:
                    break;
            }
        }

        // 保存订单确认状态
        if (orderDraftId) {
            let { id } = orderDraftId;
            let orderDraftData = await uqs.orderDraft.OrderDraft.getSheet(id);
            let { id: draftorderId, flow: draftFlow, state: draftState } = orderDraftData.brief;
            await orderDraft.OrderDraft.action(draftorderId, draftFlow, draftState, "Pass");
            this.closePage();
        } else {
            let param: [{ productId: number, packId: number }] = [] as any;
            orderItems.forEach(e => {
                e.packs.forEach(v => {
                    param.push({ productId: e.product.id, packId: v.pack.id })
                })
            });
            cart.removeFromCart(param);
        }
        // 打开下单成功显示界面
        nav.popTo(this.cApp.topKey);
        this.openVPage(OrderSuccess, result);
    }

    onSelectShippingContact = async () => {
        let cSelect = this.newC(CSelectShippingContact);
        let contactBox = await cSelect.call<BoxId>(true);
        this.orderData.shippingContact = contactBox;
        await this.exOrderContact();
    }

    onSelectInvoiceContact = async () => {
        let cSelect = this.newC(CSelectInvoiceContact);
        let contactBox = await cSelect.call<BoxId>(true);
        this.orderData.invoiceContact = contactBox;
        await this.exOrderContact();
    }

    exOrderContact = async () => {
        let shippingAssure: any = await this.orderData.shippingContact?.assure();
        let invoiceAssure:any = await this.orderData.invoiceContact?.assure();
        this.exOrderContacts = {
            "_shippingContact": (shippingAssure && shippingAssure?.obj?.mobile && shippingAssure?.obj?.email) ? true : false,
            "_invoiceContact": (invoiceAssure && invoiceAssure?.obj?.mobile && invoiceAssure?.obj?.email) ? true : false,
        };
    }

    /**
     * 打开输入或选择使用卡券界面
     */
    onCouponEdit = async () => {
        let { cCoupon } = this.cApp;
        let coupon = await cCoupon.call<any>(this.orderData.coupon);
        if (coupon) {
            await this.applyCoupon(coupon);
        }
    }

    private hasCoupons = async (): Promise<boolean> => {
         let { /* cCoupon, */ currentUser } = this.cApp;
        // let { id: currentUserId } = currentUser;
        let getValidCredits = await currentUser.getValidCredits();
        if (getValidCredits && getValidCredits.length > 0)
            return true;
        let validCoupons = await currentUser.getValidCoupons();
        // let validCoupons = await cCoupon.getValidCouponsForWebUser(currentUserId);
        if (validCoupons && validCoupons.length > 0)
            return true;
        /* let validCredits = await cCoupon.getValidCreditsForWebUser(currentUserId);
        if (validCredits && validCoupons.length > 0)
            return true; */
        return false;
    }

    /**
     * 使用优惠券后计算折扣金额和抵扣额
     */
    applyCoupon = async (coupon: any) => {

        this.removeCoupon();
        let { result: validationResult, validitydate, isValid } = coupon;
        if (validationResult === 1 && isValid === 1 && new Date(validitydate).getTime() > Date.now()) {
            this.couponAppliedData = coupon;
            let orderPriceStrategy: OrderPriceStrategy = createOrderPriceStrategy(coupon);
            orderPriceStrategy.applyTo(this.orderData, this.uqs);
            /*
            this.orderData.coupon = id;
            if (types === "coupon" || types === "vipcard") {
                // if (discount) {
                // 仍兼容原来统一折扣的模式
                if ((discountSetting && discountSetting.length > 0) || discount) {
                    let { orderData, uqs, cApp } = this;
                    let { orderItems } = orderData;
                    let { AgentPrice } = uqs.product;
                    if (orderItems !== undefined && orderItems.length > 0) {
                        // 获取每个明细中产品的agentprice;
                        let promises: PromiseLike<any>[] = [];
                        orderItems.forEach(e => {
                            promises.push(AgentPrice.table({ product: e.product.id, salesRegion: cApp.currentSalesRegion.id }));
                        });
                        let agentPrices = await Promise.all(promises);

                        if (agentPrices && agentPrices.length > 0) {
                            let couponOffsetAmount = 0;
                            for (let i = 0; i < orderItems.length; i++) {
                                let oi = orderItems[i];
                                let { product, packs } = oi;
                                // 获取明细中产品的优惠券/VIP卡折扣
                                if (discountSetting) {
                                    let thisDiscountSetting = discountSetting.find((e: any) => Tuid.equ(e.brand, product.obj.brand));
                                    discount = (thisDiscountSetting && thisDiscountSetting.discount) || discount || 0;
                                }

                                let eachProductAgentPrice = agentPrices[i];
                                for (let j = 0; j < packs.length; j++) {
                                    let pk = packs[j];
                                    let agentPrice: any = eachProductAgentPrice.find(
                                        (p: any) => p.product.id === product.id &&
                                            p.pack.id === pk.pack.id &&
                                            p.discountinued === 0 &&
                                            p.expireDate > Date.now());
                                    if (!agentPrice) break;

                                    // 折扣价格取agentPrice和折扣价格中较高者
                                    let discountPrice = Math.round(Math.max(agentPrice.agentPrice, pk.retail * (1 - discount)));
                                    // pk.price = Math.round(Math.max(agentPrice.agentPrice, pk.retail * (1 - discount)));
                                    // 最终价格取折扣价格和显示的价格（可能会有市场活动价)中较低者
                                    pk.price = Math.round(Math.min(pk.price, discountPrice));
                                    couponOffsetAmount += Math.round(pk.quantity * (pk.retail - pk.price) * -1);
                                };
                            };
                            this.orderData.couponOffsetAmount = Math.round(couponOffsetAmount);
                        };
                    }
                }
                if (preferential) {
                    this.orderData.couponRemitted = preferential * -1;
                }
                // 运费和运费减免
                this.orderData.freightFee = FREIGHTFEEFIXED;
                if (this.orderData.productAmount > FREIGHTFEEREMITTEDSTARTPOINT)
                    this.orderData.freightFeeRemitted = FREIGHTFEEFIXED * -1;
            }

            if (types === "credits") {
                this.orderData.point = Math.round(this.orderData.productAmount * 2);
            }
            */
            // 运费和运费减免
            this.orderData.freightFee = FREIGHTFEEFIXED;
            if (this.orderData.productAmount > FREIGHTFEEREMITTEDSTARTPOINT)
                this.orderData.freightFeeRemitted = FREIGHTFEEFIXED * -1;
            else
                this.orderData.freightFeeRemitted = 0;
        }
    }

    /**
     * 删除优惠券
     */
    removeCoupon = () => {
        this.orderData.coupon = undefined;
        this.couponAppliedData = {};
        this.orderData.couponOffsetAmount = 0;
        this.orderData.couponRemitted = 0;
        this.orderData.point = 0;
        this.orderData.orderItems.forEach((e: OrderItem) => e.packs.forEach((v: CartPackRow) => v.price = v.priceInit));
    }

    /*
    * 打开我的订单列表（在“我的”界面使用）
    */
    openMyOrders = async (state: string) => {

        this.openVPage(VMyOrders, state);
    }

    /**
     * 根据状态读取我的订单
     */
    getMyOrders = async (state: string) => {
        let { order } = this.uqs;
        switch (state) {
            case 'pendingpayment':
                return await order.GetPendingPayment.table(undefined);
            case 'processing':
                /* let res = await order.Order.mySheets(undefined, 1, -20);
                return res.map((el: any) => { return { ...el, state: "processing" } }); */
                let result3: any = await this.searchMyOrders({ state: "11" });
                return result3.items;
            case 'completed':
                let result2: any = await this.searchMyOrders({ state: "12" });
                return result2.items;
            case 'shipped':
                let result1: any = await this.searchMyOrders({ state: "13" });
                return result1.items;
            case 'all':
                let result:any = await this.searchMyOrders({});
                return result.items;
            default:
                break;
        }
        /* let { order } = this.uqs;
        switch (state) {
            case 'pendingpayment':
                return await order.GetPendingPayment.table(undefined);
            case 'processing':
                return await order.Order.mySheets(undefined, 1, -20);
            case 'completed':
                return await order.Order.mySheets("#", 1, -20)
            case 'all':
                let promises: PromiseLike<any>[] = [];
                promises.push(order.Order.mySheets(undefined, 1, -20));
                promises.push(order.Order.mySheets("#", 1, -20));
                let presult = await Promise.all(promises);
                return presult[0].concat(presult[1]).sort((a: any, b: any) => new Date(b.date).valueOf() - new Date(a.date).valueOf());
            default:
                break;
        } */
    }

    searchMyOrders = async (param:any) => {
        let { firstSize, pageSize, keyWord, state } = param;
        let { currentUser } = this.cApp;
        let result = new QueryPager<any>(this.uqs.JkOrder.SearchOrders, pageSize || 100000000, firstSize || 100000000);
        await result.first({
            keyWord: keyWord || undefined, state: state || undefined,
            customer: currentUser?.currentCustomer
        });
        return result;
    }

    /**
     * 打开发票信息编辑界面
     */
    onInvoiceInfoEdit = async () => {
        let cInvoiceInfo = this.newC(CInvoiceInfo); // new CInvoiceInfo(this.cApp, undefined, true);
        let { invoiceType, invoiceInfo } = this.orderData;
        let origInvoice = {
            invoiceType: invoiceType,
            invoiceInfo: invoiceInfo,
        }
        let newInvoice = await cInvoiceInfo.call<any>(origInvoice, true, true);
        this.orderData.invoiceType = newInvoice.invoiceType;
        this.orderData.invoiceInfo = newInvoice.invoiceInfo;
    }

    openOrderDetail = async (orderArg: any) => {
        let { id:orderIdA, no } = orderArg;
        let { currentUser, currentSalesRegion } = this.cApp;
        let { id: salesRegionId } = currentSalesRegion;
        let { JkOrder, customer, common, product: productx, JkCoupon, JkProduct, JkCommon, JkDeliver } = this.uqs;
        let getOrderByNo:any[] = await JkOrder.QueryID({ IDX: [JkOrder.OrderMain], keyx: { no: no } });
        if (getOrderByNo.length) {
            let { id: orderId } = getOrderByNo[0];
            let order: any = { brief: {}, data: { orderItems: [], comments: undefined } };
            let getOrderDetail: any[][] = await JkOrder.IDDetailGet<OrderMain, OrderDetail>({
                id: orderId,
                main: JkOrder.OrderMain,
                detail: JkOrder.OrderDetail,
            });
            let getOrderMainEx: any[] = await JkOrder.ID<OrderMainEx>({
                IDX: JkOrder.OrderMainEx,
                id: orderId,
            });
            let [mainArr, orderDetail] = getOrderDetail;
            if (!mainArr.length || !orderDetail.length) return;
            let { id, no, createDate: date, sumAmount: amount, shippingContact,
                invoiceContact, invoiceInfo, invoiceType, sheetId, order2BC } = mainArr[0] as any;
            shippingContact = customer.Contact.boxId(shippingContact);
            invoiceContact = customer.Contact.boxId(invoiceContact);
            invoiceInfo = customer.InvoiceInfo.boxId(invoiceInfo);
            invoiceType = common.InvoiceType.boxId(invoiceType);
            let currency = JkCommon.Currency.boxId(5);
            let promise1: PromiseLike<any>[] = [shippingContact, invoiceContact, invoiceInfo, invoiceType, currency];
            await Promise.all(promise1);
            let nCoupon: any;
            if (sheetId) {
                let getSheetOrder: any = await JkOrder.Order.getSheet(sheetId);
                let oldCoupon: any = getSheetOrder?.data?.coupon;
                if (oldCoupon && oldCoupon?.id) {
                    let getCoupon: any[] = await this.cApp.cCoupon.getCouponById(oldCoupon?.id);
                    await JkCoupon.ID({ IDX: JkCoupon.Coupon, id: oldCoupon?.id });
                    nCoupon = getCoupon;
                    if (nCoupon) nCoupon.types = EnumCouponType[nCoupon?.type];
                };
            };
            date = moment(date).format('YYYY-MM-DD HH:mm:ss');
            order.brief = { id: id, no: no, state: undefined, date: date };
            _.assign(order.data, {
                couponOffsetAmount: 0, couponRemitted: 0, salesRegion: { id: 1 }, coupon: nCoupon,
                currency: currency, webUser: currentUser, amount: amount,
                shippingContact: shippingContact, invoiceContact: invoiceContact,
                invoiceInfo: invoiceInfo, invoiceType: invoiceType,
                comments: getOrderMainEx[0]?.commentsAboutDeliver, order2BC: order2BC
            });
            let orderItemsn: any[] = [];
            if (orderDetail.length) {
                let ids = orderDetail.map((el: any) => el.id);
                let getDeliverDatailByOrderDetail = await this.uqs.JkOrder.IX({ IX: JkOrder.OrderDetailDeliver, ix: ids });
                let promise: PromiseLike<any>[] = [];
                orderDetail.forEach((el: any) => {
                    let findD: any = getDeliverDatailByOrderDetail.find((i: any) => i.ix === el.id);
                    el.deliverDetail = findD?.xi;
                    let { product } = el;
                    el.product = productx.ProductX.boxId(product);
                    promise.push(el.product);
                    promise.push(JkProduct.GetProductPrices.table({ product: product, salesRegion: salesRegionId }).then((data: any) => el.pricex = data || []));
                    promise.push(JkDeliver.GetDeliverDetailTransportation.obj({ deliverDetail: el?.deliverDetail }).then((data: any) => el.transportation = data || undefined));
                });
                await Promise.all(promise);
                orderItemsn = orderDetail.map((el: any) => {
                    let { product, item, price, quantity, pricex } = el as any;
                    let { pack } = pricex?.find((o: any) => o.pack?.id === item) || { pack: item };
                    let param: any = { id: id, transportation: el.transportation };
                    return { param: param, pack: pack, price: price, product: product, quantity: quantity, currency: undefined as any };
                });
                _.assign(order.data, {
                    orderItems: groupByProduct1(orderItemsn),
                });
            };
            this.openVPage(VOrderDetail, order);
        } else {
            // else中 表示原来的订单没有在ordermain中，故使用原来的订单查询方式,但是会有解析错误出现
            let order = await this.uqs.order.Order.getSheet(orderIdA);
            let { data } = order;
            let { orderItems } = data;
            let orderItemsGrouped = groupByProduct(orderItems);
            data.orderItems = orderItemsGrouped;
            let getCoupon:any = await this.cApp.cCoupon.getCouponById(data?.coupon?.id);
            data.coupon = getCoupon;
            this.openVPage(VOrderDetail, order);
        };
    }

    renderDeliveryTime = (pack: BoxId) => {
        let { cProduct } = this.cApp;
        return cProduct.renderDeliveryTime(pack);
    }

    renderOrderItemProduct = (product: BoxId) => {
        let { cProduct } = this.cApp;
        return cProduct.renderCartProduct(product);
    }

    /**
     * 取消订单草案
     * @param orderDraftId
     */
    onCancel = async (orderDraftId: any) => {
        let { uqs } = this.cApp;
        let { orderDraft } = uqs;
        // 保存订单状态
        let { id } = orderDraftId;
        let orderDraftData = await uqs.orderDraft.OrderDraft.getSheet(id);
        let { id: orderId, flow, state } = orderDraftData.brief;
        await orderDraft.OrderDraft.action(orderId, flow, state, "Cancel");
        this.closePage()
    }

    /**
     * 添加到购物车，修改产品信息
     */
    addToCart = async () => {
        let { cApp, orderData, removeCoupon } = this;
        let { cart } = cApp;
        removeCoupon();
        orderData.orderItems.forEach(async (v) => {
            v.packs.map(async (e) => {
                let { quantity, retail, price, pack, currency } = e;
                let { id: currencyId } = currency;
                await cart.addIncremental(v.product, pack, quantity, price, retail, currencyId);
            })
        })
        this.closePage()
        this.cApp.cCart.start();
    }

    getOrderTrackByTransNum = async (transCompany: string, transNumber: number | string) => {
        try {
            let param = { code: transCompany, no: transNumber };
            let res = await fetch(GLOABLE.LOGISTIC.ORDERTRANS, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(param)
            });
            if (!res.ok) return;
            return await res.json();
        } catch (error) {
            return;
        };
    };

    openOrderTrans = async (orderTrans: any) => {
        let { transNumber, expressLogistics } = orderTrans;
        /* 入驻的快递（可查物流） */
        /* let currTransBoxId: any = GLOABLE.settledTrans.find((v: any) => v.transCompanyId === expressLogistics?.id);
        if (currTransBoxId) {
            let { transCompany } = currTransBoxId;
            let orderTrackRult = await this.getOrderTrackByTransNum(transCompany, transNumber);
            orderTrans.orderTrackRult = orderTrackRult?.response;
        }; */
        let { result } = await appApis.JKOrder.getOrdertrans(transNumber);
        orderTrans.orderTrackRult = result;
        this.openVPage(VOrderTrans, orderTrans);
    };
}