import * as React from 'react';
import { VPage, Page, Tabs, TabCaptionComponent, TabsProps, TabProp } from "tonva-react";
import { COrder } from './COrder';
import { List, EasyDate } from "tonva-react";
import { makeObservable, observable } from 'mobx';

export const color = (selected: boolean) => selected === true ? 'text-primary' : 'text-muted';
export const OrdersPageSize: number = 15;/* 订单分页每页数量 */


function uniqueOrderNo(arr:any[]){
	let res: any[] = [], obj: any = {};
	for (let key of arr) {
		if (!obj[key.no]) {
			obj[key.no] = key?.no;
			res.push(key);
		};
	};
 	return res;
};

export class VMyOrders extends VPage<COrder> {

	/*
    @observable private pendingOrders: any[];
    @observable private processingOrders: any[];
    @observable private completedOrders: any[];
	@observable private allOrders: any[];
	*/
	private list: any[];
	private currentState: string;
    private tabs: TabProp[];
    currentPage:  number = 1;
	listAll:  any[] = [];
	constructor(c: COrder) {
        super(c);

        makeObservable<VMyOrders, "list">(this, {
            list: observable,
            currentPage: observable,
            listAll: observable,
        });
	}
	/*
    async open(param: any) {
        this.currentState = param;
        this.openPage(this.page);
	}
	*/

	init(param: any) {
		this.currentState = param;
        let { getMyOrders } = this.controller;
        let loadList = async (state: string) => {
			this.currentState = state;
			this.currentPage = 1;
			let arr: any[] = await getMyOrders(this.currentState);
			this.listAll = this.toRepeat(arr, "id");
			if (this.currentState === "processing") this.list = this.listAll;
			else {
				let arr: any[] = [];
				if (this.currentState === "all") {
					arr = await getMyOrders("processing");
					arr =  this.toRepeat(arr, "id");
				};
				this.list = [arr, this.listAll.slice(0, OrdersPageSize)].flat();
			};
			this.list = uniqueOrderNo(this.list);
		};
		let oss = [
			{ caption: '待审核', state: 'processing', icon: 'desktop' },
            { caption: '待发货', state: 'completed', icon: 'truck' },
			// { caption: '已发货', state: 'shipped', icon: 'truck' },
			{ caption: '所有订单', state: 'all', icon: 'file-text-o' },
		];
		this.tabs = oss.map(v => {
			let { caption, state, icon } = v;
			return {
				name: caption,
				caption: (selected: boolean) => TabCaptionComponent(caption, icon, color(selected)),
				content: () => {
					return <List items={this.list} item={{ render: this.renderOrder }} none="[无]" />
				},
				isSelected: this.currentState === state,
				load: async () => { await loadList(state);},
				onShown: async () => { await loadList(state);},
			};
		});
	}

	private renderOrder = (order: any, index: number) => {
		let { openOrderDetail } = this.controller;
		let { id, no, date, discription, flow } = order;
		return <div className="m-3 justify-content-between cursor-pointer" onClick={() => openOrderDetail(order)}>
			<div><span className="small text-muted">订单: </span><strong>{no}</strong></div>
			<div className="small text-muted"><EasyDate date={date} /></div>
		</div>;
	}

	header() {
		return '我的订单';
    }

    toRepeat = (arr: any[], key: string) => {
		let obj: any = {};
		return arr.filter(item => obj[item[key]] ? '' : (obj[item[key]] = true));
    };

    content(): JSX.Element {
		return <Tabs tabs={this.tabs} tabPosition="top" />;
	}

	private page = () => {

		let { getMyOrders } = this.controller;
        let oss = [
            { caption: '待审核', state: 'processing', icon: 'desktop' },
            { caption: '待发货', state: 'completed', icon: 'truck' },
            { caption: '所有订单', state: 'all', icon: 'file-text-o' },
        ];
		let tabs = oss.map(v => {
			let { caption, state, icon } = v;
			return {
				name: caption,
				caption: (selected: boolean) => TabCaptionComponent(caption, icon, color(selected)),
				content: () => {
					return <List items={this.list} item={{ render: this.renderOrder }} none="[无]" />
				},
				isSelected: this.currentState === state,
				load: async () => {
					this.currentState = state;
					this.list = await getMyOrders(this.currentState);
				}
			};
		});
		/*
        let tabs = [{
            name: '待审核',
            caption: (selected: boolean) => TabCaptionComponent("待审核", "desktop", color(selected)),
            content: () => {
                return <List items={this.processingOrders} item={{ render: this.renderOrder }} none="无待审核订单" />
            },
            isSelected: this.currentState === 'processing',
            load: async () => {
                this.currentState = 'processing';
                this.processingOrders = await getMyOrders(this.currentState);
            }
        }, {
            name: '待发货',
            caption: (selected: boolean) => TabCaptionComponent("待发货", "truck", color(selected)),
            content: () => {
                return <List items={this.completedOrders} item={{ render: this.renderOrder }} none="还没有已完成的订单" />
            },
            isSelected: this.currentState === 'completed',
            load: async () => {
                this.currentState = 'completed';
                this.completedOrders = await getMyOrders(this.currentState);
            }
        }, {
            name: '所有订单',
            caption: (selected: boolean) => TabCaptionComponent("所有订单", "file-text-o", color(selected)),
            content: () => {
                return <List items={this.allOrders} item={{ render: this.renderOrder }} none="还没有订单" />
            },
            isSelected: this.currentState === 'all',
            load: async () => {
                this.currentState = 'all';
                this.allOrders = await getMyOrders(this.currentState);
            }
		}];
		*/
		// return <Page header="我的订单" tabs={tabs} tabPosition="top" />
		//return <Tabs tabs={tabs} tabPosition='top' />
		return <Page header="我的订单">
			<Tabs tabs={tabs} tabPosition="top" />
		</Page>
	}
}