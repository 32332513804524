import { VPage, autoHideTips } from 'tonva-react';
import { CHome } from './CHome';
import React from 'react';
import { observer } from 'mobx-react-lite';
import { ConfigProvider, Form } from 'antd';
import Search from 'antd/es/input/Search';
import { observable } from 'mobx';

export const CustomHeader: JSX.Element = <div className='w-100 text-center bg-light' style={{ height:54, lineHeight:"54px" }} >
    <img src="/images/logo.svg" alt="" style={{ height: 35 }} />
</div>;

export const CustomFooter: JSX.Element = <div className='text-center bg-light py-2' style={{ height:"68px" }}>
    <div className='d-flex justify-content-around font-weight-normal fs-14 px-2' >
        <div className='d-none d-md-inline-block col-md-3'></div>
        <div>百灵威版权所有</div>
        <div>京ICP备09071033号-3 </div>
        <div className='d-none d-md-inline-block col-md-3'></div>
    </div>
    <div className='fs-12' style={{ color: "#D8D8D8" }} >
        本网站销售的化学产品仅供研发或工业应用, 不直接用于<br />
        食品、药品、临床诊断或治疗
    </div>
</div>;

export class VHomeCustom extends VPage<CHome> {
    tips = observable.box<string>();
    init(param?: any) { };

    header() { return false };

    footer() {
        return CustomFooter
    }

    content() {
        return React.createElement(observer(() => {
            let imageUrl: any = "/images/origin-example.png";/* "https://dummyimage.com/300x400/adadad/fff&text=origin example" */
            return <div className='bg-f234' >
                <div className='position-sticky' style={{top:0, zIndex:9}}>
                    {CustomHeader}
                </div>
                <div className='p-16' >
                    <div className='card p-16 text-center bg-light border-0'>
                        <div className='font-weight-bolder'>产品信息搜索</div>
                        <div className='color-68 fs-12'>获得产品技术规格、COA、SDS等技术信息</div>
                        <ConfigProvider theme={{ token: { borderRadius: 20, colorPrimary:"#B02C38" }}}>
                            <Search onSearch={this.onSearch} enterButton placeholder='请输入产品编号' className='mt-3' />
                        </ConfigProvider>
                        {autoHideTips(this.tips, tip => <div className='small text-danger text-left py-2'>* {tip}</div>)}
                    </div>
                    <div className='card p-16 bg-light mt-1 border-0 align-items-center'>
                        <img src={imageUrl} alt="产品编号示例" width="292px" height="474px" onError={(evt: any) => {
                             evt.currentTarget.src = "https://dummyimage.com/300x400/adadad/fff&text=origin example";
                        }}  />
                        <div className='text-center mt-2 color-lakeBlue fs-12'>如需帮助，请致电客服热线 4006667788</div>
                    </div>
                </div>
            </div>
        }));
    };

    onSearch = async (key: string) => {
        if (!key) {
            this.tips.set("请输入产品编号!");
            return;
        }
        let { JkProduct } = this.controller.uqs;
        let result: any = await JkProduct.GetProductByOrigin.obj({ origin: key, salesRegion: 1 });
        if (!result) {
            this.tips.set("未查询到产品,请输入正确的产品编号!");
            return;
        }
        let { id } = result;
        // let url: any = `?type=productCustom&product=${id.id}`;
        // window.location.href = url;
        await this.controller.cApp.cProduct.showProductDetailCustom(id);
    }
}