import { View, autoHideTips } from 'tonva-react';
import { CProduct } from './CProduct';
import { observer } from 'mobx-react';
import React from 'react';
import { VPagePDF } from './VPagePDF';
import { observable } from 'mobx';

export class VProductMaterialView extends View<CProduct> {
    data: any = {};
    input: HTMLInputElement;
    input2: HTMLInputElement;
    productOriginTip = observable.box<string>();
	productLotTipNone = observable.box<string>();

    render(param?: any): JSX.Element {
        this.data = param;
        return <>{this.contentPage()}</>;
    }

    contentPage() {
        return React.createElement(observer(() => {
            let { verifyCode, getVerifyCode } = this.controller;
            return <div className='card p-16 bg-light my-1 border-0'>
                <div>
                    <span className='font-weight-bold' >质检报告</span>
                    <small className='fs-12 d-block' style={{color:"#686868"}} >Certificate of Analysis (COA)</small>
                    <input onKeyDown={(e: any) => { if (e.keyCode === 13) this.searchProductPDF("coa"); }}
                        ref={(v: any) => this.input = v} placeholder='产品批号' className='form-control fs-12' style={{margin:"12px 0px" }} />
                    {autoHideTips(this.productLotTipNone, tip => <div className='small text-danger py-2'>* {tip}</div>)}
                    <button onClick={() => { this.searchProductPDF("coa") }} className='w-100 btn text-light bg-b238 fs-12'>查询</button>
                </div>
                <hr />
                <div>
                    <span className='font-weight-bold' >化学品安全技术说明书</span>
                    <small className='fs-12 d-block' style={{color:"#686868"}} >Safety Data Sheet (SDS)</small>
                    <div className='d-flex align-items-center justify-content-between'  style={{margin:"12px 0px" }}>
                        <input onKeyDown={(e: any) => { if (e.keyCode === 13) this.searchProductPDF("msds"); }}
                            ref={(v: any) => this.input2 = v} placeholder='验证码' className='form-control flex-fill fs-12' />
                        <div><img src={verifyCode} alt="" className="border p-1 rounded-lg w-5c h-2c mx-2" /></div>
                        <div className="cursor-pointer fs-12 color-lakeBlue" style={{ width:"110px" }}
                            onClick={(e: any) => { e.preventDefault(); getVerifyCode() }}>
                            <i className="fa fa-refresh" aria-hidden="true" /> 换一张</div>
                    </div>
                    {autoHideTips(this.productOriginTip, tip => <div className='small text-danger py-2'>* {tip}</div>)}
                    <button onClick={() => { this.searchProductPDF("msds") }} className='w-100 btn text-light bg-b238 fs-12'>查询</button>
                </div>
            </div>
        }));
    };

    searchProductPDF = async (type: string) => {
        let value1: any = this.input?.value;
        let value2: any = this.input2?.value;
        if (type === "coa" && !value1) return;
        if (type === "msds" && !value2) return;
        let { origin } = this.data;
        let param: any = { origin: origin, lang: "CN", lot: value1, captcha: value2, materialType: type };
        let res: any = await this.controller.getPDFFileUrl2(param);
        if (res === undefined) return;
        if (res.status) {
            if (type === 'coa') this.productLotTipNone.set(res.msg);
            else {
                this.productOriginTip.set(res.msg);
            };
        } else {
            this.openVPage(VPagePDF, res);
        };
    };
}