import { VPage, Page, EasyDate, TabProp, TabCaptionComponent, Tabs, FA } from 'tonva-react';
import { CPointProduct } from './CPointProduct';
import { List } from "tonva-react";
import { observer } from 'mobx-react-lite';
import { makeObservable, observable } from 'mobx';
import { color } from 'order/VMyOrders';

export const ExchangeState: { [state: string | number]: string } = {
    12: "待发运",
    13: "已发运",
    14: "已完成",
    15: "已取消",
    "canceled": "已取消",
};

export class VExchangeHistory extends VPage<CPointProduct> {

    exchangeHistory: any[] = [];        /*积分产品列表 */

    private currentState: number = 0;
	private tabs: TabProp[];
	constructor(c: CPointProduct) {
        super(c);
        makeObservable(this, {
            exchangeHistory: observable
        });
    }

    async open(param?: any) {
        let oss = [
			{ caption: '待发运', state: 12, icon: 'desktop' },
			{ caption: '已发运', state: 13, icon: 'truck' },
			{ caption: '已取消', state: 15, icon: 'times-circle' },
			{ caption: '所有订单', state: 0, icon: 'file-text-o' },
		];
        this.tabs = oss.map(v => {
            let { caption, state, icon } = v;
            return {
                name: caption,
                caption: (selected: boolean) => TabCaptionComponent(caption, icon, color(selected)),
                content: () => {
                    return <List items={this.exchangeHistory} item={{ render: this.renderExchangeHistory }} none="暂无记录" />
                },
                isSelected: this.currentState === state,
                load: async () => {
                    this.currentState = state;
                    this.exchangeHistory = await this.controller.getMyPointOrder(this.currentState);
                }
            };
        });
        this.openPage(this.page);
    }

    private renderExchangeHistory = (pointProduct: any, index: number) => {
        let { openOrderDetail } = this.controller;
        let { id, no, date, orderType, state } = pointProduct;
        let orderState: string = state ? ExchangeState[state] : "";
        return <div className="row mx-0 cursor-pointer p-2" onClick={() => openOrderDetail(id, orderType)}>
            <div className="col-9 col-sm-10 px-0 row mx-0">
                <div className="col-12 col-sm-7 px-0"><span className="small text-muted">兑换单号: </span><strong>{no}</strong></div>
                <div className="col-12 col-sm-5 px-0 small align-self-center">{orderState}</div>
            </div>
            <div className="col-3 col-sm-2 col-sm-2 px-0 small text-muted text-right align-self-center">
                <EasyDate date={date} /> <FA name='angle-right' size='2x' className='text-dark ml-1' /> </div>
        </div>;
    }

    private page = observer(() => {
        return <Page header="兑换历史记录">
            <Tabs tabs={this.tabs} tabPosition="top" />
            {/* <List items={this.exchangeHistory} item={{ render: this.renderExchangeHistory }} none="暂无记录"></List> */}
        </Page>;
    });
}